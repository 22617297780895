import React from 'react';
import { Stat, StatHelpText, StatLabel, StatNumber } from '@chakra-ui/react';

export default function RoomStatistic(props) {
  return (
    <Stat>
      <StatLabel fontSize="xl">{props.label}</StatLabel>
      <StatNumber color="blue.400" fontSize="5xl" fontWeight="bold">{props.value}</StatNumber>
      {!!props.helpText && <StatHelpText fontSize="lg">{props.helpText}</StatHelpText>}
    </Stat>
  );
}
