import React from 'react';
import { Flex, Text, Tooltip } from '@chakra-ui/react';
import { formatList } from '../../../../commonUtils';

export default function Cell(props) {
  const isBlank = props.cell.hasOwnProperty('blank');
  const isShaded = props.cell.hasOwnProperty('shaded');
  const classes = (isBlank ? '' : 'hover-pointer');
  const content = (props.reveal ? props.cell.text : props.cell.currentText) || '';
  const contentBottom = (content.length <= 1 ? (props.isCramped ? -1 : 0) : (props.isCramped ? '20%' : '25%'));
  const numberSize = (props.isCramped ? '0.4em' : '0.45em');
  const fontSize = (content.length <= 1 ? (props.isCramped ? '0.9em' : '1em') : numberSize);
  const textColor = (props.isIncorrect ? 'red' : 'inherit');
  const backgroundColor = (
    isBlank ? 'black' : (
      props.isSelected ?
        `${props.playerColor}.400` :
        (props.isHighlighted ?
          `${props.playerColor}.200` :
          (isShaded ?
            'gray.300' :
            'white'
          )
        )
      )
  );
  const borderColor = (props.occupyingPlayers.length ?
    `${props.occupyingPlayers[0].color}.${props.occupyingPlayers[0].color === props.playerColor ? '600' : '400'}` :
    'black'
  );
  const borderWidth = (props.occupyingPlayers.length ? 3 : 1);

  const cell = (
    <Flex justify="center" borderColor={borderColor} borderWidth={borderWidth} backgroundColor={backgroundColor} textColor="black"
          position="relative" className={classes} onClick={props.onClick}>
      {props.cell.hasOwnProperty('circled') && (
        <Flex position="absolute" height="100%" width="100%">
          <svg height="100%" width="100%">
            <circle r="48%" cx="50%" cy="50%" stroke="black" strokeWidth="1" fill="none" />
          </svg>
        </Flex>
      )}
      {props.cell.hasOwnProperty('number') && (
        <Text fontSize={numberSize} position="absolute" top={props.isCramped ? -0.5 : 0} left="0.5">
          {props.cell.number}
        </Text>
      )}
      <Text fontSize={fontSize} fontWeight="bold" textColor={textColor} position="absolute" bottom={contentBottom} userSelect="none">
        {content}
      </Text>
    </Flex>
  );
  if (props.occupyingPlayers.length) {
    return (
      <Tooltip label={formatList(props.occupyingPlayers.map(player => player.name))}>
        {cell}
      </Tooltip>
    )
  } else {
    return cell;
  }
}
