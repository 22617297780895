import React from 'react';
import { Button, Flex, Heading } from '@chakra-ui/react';
import { DEFAULT_COLOR, MAX_PLAYER_NAME_LENGTH, PlayerEditorModes } from '../../../../constants';
import { validatePlayerName } from '../../../../models/player';
import Card from '../../common/card/Card';
import PlayerColorSetting from './PlayerColorSetting';
import PlayerNameInput from './PlayerNameInput';

export default class PlayerSettings extends React.Component {
  constructor(props) {
    super(props);
    const player = this.getPlayer(props);
    this.state = {
      invalid: false,
      name: player?.name || '',
      color: player?.color || DEFAULT_COLOR,
    };
    this.handleColorChanged = this.handleColorChanged.bind(this);
    this.handleNameChanged = this.handleNameChanged.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const prevPlayers = this.getAllPlayers(prevProps);
    const players = this.getAllPlayers();
    const playerID = this.props.playerID;
    if ((!prevProps.playerID && playerID && players.hasOwnProperty(playerID)) ||
      (playerID && !prevPlayers.hasOwnProperty(playerID) && players.hasOwnProperty(playerID))) {
      const player = players[playerID];
      this.setState({name: player.name});
    }
  }

  getAllPlayers(props) {
    if (!props) {
      props = this.props;
    }
    return {...props.players};
  }

  getPlayer(props) {
    if (!props) {
      props = this.props;
    }
    return this.getAllPlayers()[props.playerID];
  }

  handleColorChanged(color) {
    this.setState({color: color});
  }

  handleNameChanged(event) {
    const name = event.target.value.trim();
    this.setState({invalid: !name, name: name.substring(0, MAX_PLAYER_NAME_LENGTH)});
  }

  handleSubmit() {
    if (!validatePlayerName(this.state.name.trim())) {
      this.setState({invalid: true});
    } else {
      if (!this.props.playerID) {
        this.props.createNewPlayer(this.state.name, this.state.color);
      } else {
        const player = this.getPlayer();
        if (this.state.name !== player.name || this.state.color !== player.color) {
          this.props.changePlayerSettings(this.props.playerID, this.state.name, this.state.color);
        }
      }
      if (this.props.onSubmit) {
        this.props.onSubmit();
      }
    }
  }

  render() {
    const heading = (this.props.mode === PlayerEditorModes.CREATE ? 'Create New Player' : 'Edit Player Settings');
    const buttonLabel = (this.props.mode === PlayerEditorModes.CREATE ? 'Create' : 'Save');
    return (
      <Card className="game-settings" px={10} py={6}>
        <Heading mb={5} textAlign="center">{heading}</Heading>
        <PlayerNameInput name={this.state.name} invalid={this.state.invalid} onChange={this.handleNameChanged} />
        <PlayerColorSetting selectedColor={this.state.color} onChange={this.handleColorChanged} />
        <Flex justify="center" mt={8} mb={3}>
          <Button colorScheme="blue" size="lg" w="25%" isDisabled={this.state.invalid} onClick={this.handleSubmit}>
            {buttonLabel}
          </Button>
        </Flex>
      </Card>
    );
  }
}
