import { ActionTypes } from '../actions/action_creators';
import { checkSquare, getCoordinatesOfCellWithNumber, range } from '../../commonUtils';
import { ClueDirections, EventTypes, PLAYER_ID_KEY, StatusCodes } from '../../constants';
import { GameSettings } from '../../models/game';

const WEBSOCKET_CONNECTION_REFUSED_ERROR_MESSAGE = '`redux-websocket` error';

let playerNames = {};

export function getPlayerName(playerID) {
  return playerNames[playerID] || playerID;
}

function newStoreData() {
  return {
    connected: false,
    error: null,
    errorContext: null,
    playerID: localStorage.getItem(PLAYER_ID_KEY) || null,
    redirectToHome: false,
    roomID: null,
    room: null,
    game: null,
    correctCells: null,
    revealedWord: null,
    gameSettings: new GameSettings(),
    gameStarting: false,
    players: {},
    roomLinkRequestSucceeded: false,
    roomLinkRequests: {},
    rooms: {},
    allPlayers: {},
  };
}

function newCorrectCells(game) {
  return range(game.puzzle.size.rows).map(_ => range(game.puzzle.size.columns).map(_ => true));
}

function shouldIgnoreError(eventType, status) {
  return false;
}

function shouldPropagateError(eventType) {
  return (eventType === EventTypes.JOIN_ROOM_WITH_CODE);
}

function handleError(storeData, event) {
  const { eventType, error, status } = event.payload;
  console.log(`Request to ${eventType} failed: ${error} (${status})`);
  if (shouldIgnoreError(eventType, status)) {
    return storeData;
  }
  if (shouldPropagateError(eventType)) {
    return {...storeData, errorContext: event.payload};
  }
  return {...storeData, error: `Failed to ${eventType.replaceAll('_', ' ')}.`};
}

function handleNewGame(storeData, newGame) {
  if (newGame?.error) {
    return {...storeData, error: newGame.error, gameStarting: false};
  }
  if (!newGame) {
    return {...storeData, game: null, correctCells: null, gameStarting: false};
  }
  if (newGame.gameID === storeData.game?.gameID) {
    if (!storeData.correctCells) {
      return {...storeData, correctCells: newCorrectCells(newGame)};
    }
    return storeData;
  }
  let newPlayers = {...storeData.players};
  return {
    ...storeData,
    game: newGame,
    correctCells: newCorrectCells(newGame),
    gameStarting: false,
    players: newPlayers,
    redirectToHome: false,
  };
}

function handleGameCreationFailed(storeData, _) {
  console.log('New game creation failed.');
  return {...storeData, gameStarting: false};
}

function handleGameStarting(storeData, _) {
  console.log('New game starting...');
  return {...storeData, gameStarting: true};
}

function handleGameStarted(storeData, event) {
  const { game } = event.payload;
  console.log(`New game started: ${game.gameID}`);
  return handleNewGame(storeData, game);
}

function handleGameSettingsChanged(storeData, event) {
  const { settings } = event.payload;
  console.log('Game settings changed.');
  return {...storeData, gameSettings: settings};
}

function handleGameEnded(storeData, event) {
  const { gameID, finishedTime } = event.payload;
  if (gameID === storeData.game.gameID) {
    const newGame = {...storeData.game, finishedTime: finishedTime};
    return {...storeData, game: newGame};
  }
  return storeData;
}

function handleRoomHostReassigned(storeData, event) {
  const { newHostPlayerID } = event.payload;
  console.log(`${getPlayerName(newHostPlayerID)} is now the host.`);
  const newRoom = {...storeData.room, hostPlayerID: newHostPlayerID};
  return {...storeData, room: newRoom};
}

function handlePlayerJoinedRoom(storeData, event) {
  const { roomID, playerID, players } = event.payload;
  const player = players[playerID];
  console.log(`${player.name} has joined the room.`);
  Object.entries(players).forEach(([playerID, player]) => {
    if (storeData.players.hasOwnProperty(playerID)) {
      player.score = storeData.players[playerID].score;
    }
    if (!playerNames.hasOwnProperty(playerID)) {
      playerNames[playerID] = player.name;
    }
  });
  let newStore = {...storeData, players: players};
  if (storeData.room) {
    newStore.room = {...storeData.room, playerIDs: Object.keys(players)};
  }
  if (playerID === storeData.playerID) {
    newStore.redirectToHome = false;
    newStore.roomID = roomID;
  }
  return newStore;
}

function handlePlayerLeftRoom(storeData, event) {
  const { roomID, playerID, newHostPlayerID } = event.payload;
  if (roomID !== storeData.roomID) {
    console.log(`Ignoring player left event for room ${roomID}.`);
    return storeData;
  }
  let newStore = {...storeData};
  if (storeData.players.hasOwnProperty(playerID)) {
    if (playerID === storeData.playerID) {
      const newPlayer = {...storeData.players[playerID], currentRoomID: null, score: 0};
      return {...newStoreData(), connected: true, players: {[playerID]: newPlayer}, redirectToHome: true};
    }
    const player = storeData.players[playerID];
    console.log(`${player.name} has left the room.`);
    let newPlayer = {...player, active: false};
    newStore.players = {...storeData.players, [playerID]: newPlayer};
  } else {
    console.log(`Ignoring player left event for unknown player ${playerID}.`);
  }
  if (newHostPlayerID) {
    console.log(`${getPlayerName(newHostPlayerID)} is now the host.`);
    newStore.room = {...storeData.room, hostPlayerID: newHostPlayerID};
  }
  return newStore;
}

function handlePlayerChangedName(storeData, event) {
  const { playerID, name, color, prevName } = event.payload;
  if (!storeData.players.hasOwnProperty(playerID)) {
    console.log(`Cannot change name of unknown player "${playerID}".`);
    return storeData;
  }
  console.log(`Player changed name from "${prevName}" to "${name}" (color: ${color}).`);
  playerNames[playerID] = name;
  const newPlayer = {...storeData.players[playerID], name: name, color: color};
  const newPlayers = {...storeData.players, [playerID]: newPlayer};
  return {...storeData, players: newPlayers};
}

function handlePlayerJoined(storeData, event) {
  const { player } = event.payload;
  console.log(`${player.name} has joined the game.`);
  let newPlayers = {...storeData.players, [player.playerID]: {...player, score: player.score || storeData.players[player.playerID]?.score}};
  let newStoreData = {...storeData, players: newPlayers};
  if (storeData.game && !storeData.game.playerIDs.includes(player.playerID)) {
    newStoreData.game = {...storeData.game, playerIDs: storeData.game.playerIDs.concat(player.playerID)};
    newStoreData.correctCells = newCorrectCells(storeData.game);
  }
  return newStoreData;
}

function handleHostAbandonedGame(storeData, event) {
  const gameID = event.payload.context.gameID;
  console.log(`Host abandoned game ${gameID}.`);
  return handleNewGame(storeData, null);
}

function handleHostKickedPlayer(storeData, event) {
  const { playerID } = event.payload;
  if (playerID === storeData.playerID) {
    const newPlayer = {...storeData.players[playerID], currentRoomID: null, score: 0};
    return {...newStoreData(), connected: true, players: {[playerID]: newPlayer}, redirectToHome: true};
  }
  console.log(`Host kicked ${getPlayerName(playerID)}.`);
  const newPlayer = {...storeData.players[playerID], active: false};
  const newPlayers = {...storeData.players, [playerID]: newPlayer};
  return {...storeData, players: newPlayers};
}

function handlePlayerWentActive(storeData, event) {
  const { playerID, players } = event.payload;
  Object.entries(players).forEach(([playerID, player]) => {
    if (storeData.players.hasOwnProperty(playerID)) {
      player.score = storeData.players[playerID].score;
    }
    if (!playerNames.hasOwnProperty(playerID)) {
      playerNames[playerID] = player.name;
    }
  });
  console.log(`${getPlayerName(playerID)} went active.`);
  return {...storeData, players: players};
}

function handlePlayerWentInactive(storeData, event) {
  const { playerID } = event.payload;
  if (storeData.players.hasOwnProperty(playerID)) {
    console.log(`${getPlayerName(playerID)} went inactive.`);
    const newPlayer = {...storeData.players[playerID], active: false};
    const newPlayers = {...storeData.players, [playerID]: newPlayer};
    return {...storeData, players: newPlayers};
  }
  console.log(`Ignoring status change for unknown player ${playerID}.`);
  return storeData;
}

function handlePlayerMovedToCoords(storeData, event) {
  const { playerID, coords } = event.payload;
  console.log(`${getPlayerName(playerID)} moved to (${coords}).`);
  const newPlayerCoordinates = {...storeData.game.playerCoordinates, [playerID]: coords};
  const newGame = {...storeData.game, playerCoordinates: newPlayerCoordinates};
  return {...storeData, game: newGame};
}

function handlePlayerChangedCellContents(storeData, event) {
  const { playerID, coords, text, newCoords } = event.payload;
  const [i, j] = coords;
  console.log(`${getPlayerName(playerID)} set the contents of cell (${coords}) to "${text}".`);
  let newRow = [...storeData.game.puzzle.grid[i]];
  newRow[j].currentText = text;
  let newGrid = [...storeData.game.puzzle.grid];
  newGrid[i] = newRow;
  const newPuzzle = {...storeData.game.puzzle, grid: newGrid};
  let newGame = {...storeData.game, puzzle: newPuzzle};
  if (newCoords) {
    newGame.playerCoordinates[playerID] = newCoords;
  }
  return {...storeData, game: newGame};
}

function getUpdatedGridForRevealedWord(puzzle, direction, index) {
  let newGrid = [...puzzle.grid];
  const startingCoords = getCoordinatesOfCellWithNumber(puzzle, index);
  if (direction === ClueDirections.ACROSS) {
    const i = startingCoords[0];
    let j = startingCoords[1];
    while (true) {
      const cell = puzzle.grid[i][j];
      if (cell.hasOwnProperty('blank') || j >= puzzle.size.columns) {
        break;
      }
      if (!checkSquare(puzzle, i, j, false)) {
        newGrid[i][j].currentText = puzzle.grid[i][j].text;
      }
      j += 1;
    }
  } else {
    const j = startingCoords[1];
    let i = startingCoords[0];
    while (true) {
      const cell = puzzle.grid[i][j];
      if (cell.hasOwnProperty('blank') || i >= puzzle.size.rows) {
        break;
      }
      if (!checkSquare(puzzle, i, j, false)) {
        newGrid[i][j].currentText = puzzle.grid[i][j].text;
      }
      i += 1;
    }
  }
  return newGrid;
}

function handlePlayerRevealedWord(storeData, event) {
  const { playerID, direction, index, answer } = event.payload;
  console.log(`${getPlayerName(playerID)} revealed ${index}-${direction.toTitleCase()} (${answer}).`);
  const newPuzzle = {...storeData.game.puzzle, grid: getUpdatedGridForRevealedWord(storeData.game.puzzle, direction, index)};
  const newGame = {...storeData.game, puzzle: newPuzzle};
  return {...storeData, game: newGame, revealedWord: {playerID, direction, index, answer}};
}

const eventHandlers = {
  [EventTypes.ERROR]: handleError,
  [EventTypes.GAME_CREATION_FAILED]: handleGameCreationFailed,
  [EventTypes.GAME_STARTING]: handleGameStarting,
  [EventTypes.GAME_STARTED]: handleGameStarted,
  [EventTypes.GAME_SETTINGS_CHANGED]: handleGameSettingsChanged,
  [EventTypes.GAME_ENDED]: handleGameEnded,
  [EventTypes.ROOM_HOST_REASSIGNED]: handleRoomHostReassigned,
  [EventTypes.PLAYER_JOINED_ROOM]: handlePlayerJoinedRoom,
  [EventTypes.PLAYER_LEFT_ROOM]: handlePlayerLeftRoom,
  [EventTypes.PLAYER_CHANGED_NAME]: handlePlayerChangedName,
  [EventTypes.PLAYER_JOINED]: handlePlayerJoined,
  [EventTypes.HOST_ABANDONED_GAME]: handleHostAbandonedGame,
  [EventTypes.HOST_KICKED_PLAYER]: handleHostKickedPlayer,
  [EventTypes.PLAYER_WENT_ACTIVE]: handlePlayerWentActive,
  [EventTypes.PLAYER_WENT_INACTIVE]: handlePlayerWentInactive,
  [EventTypes.PLAYER_MOVED_TO_COORDS]: handlePlayerMovedToCoords,
  [EventTypes.PLAYER_CHANGED_CELL_CONTENTS]: handlePlayerChangedCellContents,
  [EventTypes.PLAYER_REVEALED_WORD]: handlePlayerRevealedWord,
}

function handleWebsocketEvent(storeData, event) {
  if (event.hasOwnProperty('message')) {
    event = JSON.parse(event.message);
  }
  const eventType = event.eventType;
  if (eventHandlers.hasOwnProperty(eventType)) {
    const handler = eventHandlers[eventType];
    return handler(storeData, event);
  } else {
    console.log(`Ignoring event with unknown type: ${eventType} (${JSON.stringify(event)})`);
    return storeData || newStoreData();
  }
}

export function GameReducer(storeData, action) {
  let response;
  switch (action.type) {
    case ActionTypes.CREATE_NEW_ROOM:
    case ActionTypes.FETCH_ROOM:
      const room = action.payload;
      if (!room) {
        console.log(`Failed to ${action.type === ActionTypes.CREATE_NEW_ROOM ? 'create' : 'fetch'} room.`);
        return {...storeData, roomID: null, room: null};
      }
      if (room.error) {
        if (action.type === ActionTypes.CREATE_NEW_ROOM && room.status) {
          return {...storeData, errorContext: {...room, eventType: action.type}};
        }
        return {...storeData, error: room.error};
      }
      if (room.kickedPlayerIDs.hasOwnProperty(storeData.playerID)) {
        return {...storeData, error: 'Failed to join room.', roomID: null, room: null};
      }
      if (room.playerIDs.includes(storeData.playerID)) {
        return {...storeData, redirectToHome: false, roomID: room.roomID, room: room};
      }
      return {...storeData, roomID: room.roomID};
    case ActionTypes.FETCH_ROOMS:
      response = action.payload;
      if (response.error) {
        return {...storeData, error: response.error};
      }
      let playerNames = storeData.rooms.playerNames;
      let rooms = storeData.rooms.rooms;
      if (response.page === 1) {
        playerNames = response.playerNames;
        rooms = response.rooms;
      } else {
        playerNames = {...playerNames, ...response.playerNames};
        rooms = rooms.concat(response.rooms);
      }
      const newRooms = {...response, playerNames: playerNames, rooms: rooms};
      return {...storeData, rooms: newRooms};
    case ActionTypes.FETCH_CURRENT_GAME:
    case ActionTypes.FETCH_GAME:
    case ActionTypes.FETCH_NEW_GAME:
      const newGame = action.payload;
      return handleNewGame(storeData, newGame);
    case ActionTypes.CREATE_NEW_PLAYER:
    case ActionTypes.FETCH_CURRENT_PLAYER:
    case ActionTypes.FETCH_PLAYER:
      const player = action.payload;
      if (!player) {
        console.log(`Failed to ${action.type === ActionTypes.CREATE_NEW_PLAYER ? 'create' : 'fetch'} player.`);
        localStorage.removeItem(PLAYER_ID_KEY);
        return {...storeData, playerID: null};
      }
      if (player.error) {
        return {...storeData, error: player.error};
      }
      let newStore = {...storeData};
      if (action.type === ActionTypes.CREATE_NEW_PLAYER) {
        localStorage.setItem(PLAYER_ID_KEY, player.playerID);
        newStore.playerID = player.playerID;
      }
      return newStore;
    case ActionTypes.FETCH_PLAYERS:
      response = action.payload;
      if (response.error) {
        return {...storeData, error: response.error};
      }
      let players = storeData.allPlayers.players;
      if (response.page === 1) {
        players = response.players;
      } else {
        players = players.concat(response.players);
      }
      const allPlayers = {...response, players: players};
      return {...storeData, allPlayers: allPlayers};
    case ActionTypes.FETCH_ROOM_LINK_REQUESTS:
      response = action.payload;
      if (response.error) {
        return {...storeData, error: response.error};
      }
      let newRequests = storeData.roomLinkRequests.requests;
      if (response.page === 1) {
        newRequests = response.requests;
      } else {
        newRequests = newRequests.concat(response.requests);
      }
      const newRoomLinkRequests = {...response, requests: newRequests};
      return {...storeData, roomLinkRequests: newRoomLinkRequests};
    case ActionTypes.REQUEST_NEW_ROOM_LINK:
      const roomLinkRequest = action.payload;
      if (roomLinkRequest.error) {
        const error = (roomLinkRequest.status === StatusCodes.CONFLICT ? 'Your previous request has not yet been approved.' : roomLinkRequest.error);
        return {...storeData, error: error};
      }
      console.log(`Created room link request ${roomLinkRequest.requestID}.`);
      return {...storeData, roomLinkRequestSucceeded: true};
    case ActionTypes.RESOLVE_ROOM_LINK_REQUEST:
      const request = action.payload;
      if (request.error) {
        return {...storeData, error: request.error};
      }
      let newReqs = {...storeData.roomLinkRequests};
      newReqs.requests.forEach(req => {
        if (req.requestID === request.requestID) {
          req.resolution = request.resolution;
          req.resolvedTime = request.resolvedTime;
        }
      });
      return {...storeData, roomLinkRequests: newReqs};
    case ActionTypes.CLEAR_CURRENT_GAME:
      const { gameID } = action.payload;
      if (storeData.game?.gameID === gameID) {
        return {...storeData, game: null, correctCells: null};
      }
      return storeData;
    case ActionTypes.CLEAR_ERROR:
      const { error } = action.payload;
      if (storeData.error === error) {
        return {...storeData, error: null};
      }
      if (storeData.errorContext === error) {
        return {...storeData, errorContext: null};
      }
      return storeData;
    case ActionTypes.CLEAR_ROOM_LINK_REQUEST_SUCCEEDED:
      return {...storeData, roomLinkRequestSucceeded: false};
    case ActionTypes.CLEAR_REVEALED_WORD:
      return {...storeData, revealedWord: null};
    case ActionTypes.UPDATE_CELL_CORRECTNESS:
      const { coords, isCorrect } = action.payload;
      const [i, j] = coords;
      let row = [...storeData.correctCells[i]];
      row[j] = isCorrect;
      let cells = [...storeData.correctCells];
      cells[i] = row;
      return {...storeData, correctCells: cells};
    case ActionTypes.UPDATE_ALL_CORRECT_CELLS:
      const { correctCells } = action.payload;
      return {...storeData, correctCells: correctCells};
    case ActionTypes.REDUX_WEBSOCKET_OPEN:
      return {...storeData, connected: true};
    case ActionTypes.REDUX_WEBSOCKET_CLOSED:
      return {...storeData, connected: false};
    case ActionTypes.REDUX_WEBSOCKET_ERROR:
      const { message, originalAction } = action.meta;
      if (!originalAction && message === WEBSOCKET_CONNECTION_REFUSED_ERROR_MESSAGE) {
        return {...storeData, error: 'Failed to connect to server. Trying to reconnect...'};
      }
      return storeData;
    case ActionTypes.REDUX_WEBSOCKET_MESSAGE:
      return handleWebsocketEvent(storeData, action.payload);
    default:
      return storeData || newStoreData();
  }
}
