import React from 'react';
import { Flex, Heading } from '@chakra-ui/react';
import { ClueDirections } from '../../../../constants';
import Clue from './Clue';

export default function ClueList(props) {
  const heading = (props.direction === ClueDirections.ACROSS ? 'Across' : 'Down');
  const textAlign = (props.direction === ClueDirections.ACROSS ? 'left' : 'right');
  return (
    <Flex flexDirection="column" fontSize="lg" maxH="100%">
      <Heading size="lg" pb={2} fontFamily="Roboto Black">{heading}</Heading>
      <Flex flexDirection="column" maxH="100%" overflowY="auto">
        {props.clues.map(clue =>
          <Clue key={clue.index} id={`${clue.index}-${props.direction}`} clue={clue} textAlign={textAlign}
                isSelected={props.isActive && props.selectedClueIndex === clue.index} playerColor={props.playerColor}
                onClick={() => props.onClick(clue.index, props.direction)} />
        )}
      </Flex>
    </Flex>
  );
}
