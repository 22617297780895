import React from 'react';
import {Box, Flex, ListItem, Spacer, Text} from '@chakra-ui/react';
import { faUserTie } from '@fortawesome/free-solid-svg-icons';
import PlayerListItemIcons from './PlayerListItemIcons';
import Icon from '../Icon';

export default function PlayerListItem(props) {
  return (
    <ListItem className="list-group-item">
      <Flex align="center">
        <Text cursor="default" userSelect="none" mr={3}>
          {props.player.name}
          {props.isHost && <Text as="span" color={`${props.player.color}.400`} ml={3}><Icon id="host-icon" icon={faUserTie} title="Host" clickable={false} /></Text>}
        </Text>
        <Box bg={`${props.player.color}.400`} borderRadius="md" title={props.player.color} width="1.2em" height="1.2em" />
        <Spacer minW={10} />
        {(props.isCurrentPlayer || props.currentPlayerIsHost) && <PlayerListItemIcons {...props} />}
      </Flex>
    </ListItem>
  );
}
