import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import { Text } from '@chakra-ui/react';
import Bold from '../../common/Bold';

export default function Clue(props) {
  let backgroundColor = 'inherit';
  let textColor = 'inherit';
  let fontWeight = 'normal';
  let classes = 'hover-pointer';
  if (props.isSelected) {
    backgroundColor = `${props.playerColor}.200`;
    textColor = 'black';
    fontWeight = '600';
  } else {
    classes += ' unselected-clue';
  }
  return (
    <Text key={props.clue.index} id={props.id} backgroundColor={backgroundColor} textColor={textColor} fontWeight={fontWeight}
          fontFamily="Open Sans,Roboto,sans-serif" className={classes} onClick={props.onClick} px={2} py={1}
          textAlign={props.textAlign} borderRadius={6}>
      <Bold>{props.clue.index}.</Bold> {ReactHtmlParser(props.clue.text)}
    </Text>
  );
}
