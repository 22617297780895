/*
 NOTE: This file must be manually kept in sync with server/src/models/roomLinkRequest.mjs.
 I regret my decisions, and JavaScript is a horrible, horrible language.
 */

export const RoomLinkRequestResolution = {
  APPROVED: 'approved',
  REJECTED: 'rejected',
  UNRESOLVED: 'unresolved',
};
