/*
 NOTE: This file must be manually kept in sync with server/src/models/game.mjs.
 I regret my decisions, and JavaScript is a horrible, horrible language.
 */

import { DEFAULT_WEEKDAY } from '../constants';

export class GameSettings {
  static default(roomID, playerIDs) {
    return new GameSettings(roomID, null, null, null, playerIDs);
  }

  constructor(roomID, weekday, startDate, endDate, playerIDs) {
    this.roomID = roomID;
    this.weekday = weekday || DEFAULT_WEEKDAY;
    this.startDate = startDate || null;
    this.endDate = endDate || null;
    this.playerIDs = playerIDs || [];
  }
}
