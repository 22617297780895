/*
 NOTE: This file must be manually kept in sync with server/src/constants.mjs.
 I regret my decisions, and JavaScript is a horrible, horrible language.
 */

import config from './config.json';

export const ADMIN_PLAYER_IDS = new Set(config.admin.playerIDs);

const SERVER_HOST = config.server.host;
const SERVER_PORT = config.server.port;
export const APP_BASE = config.webapp.url;
export const API_BASE = `${config.server.protocol}://${SERVER_HOST}:${SERVER_PORT}/api`;
export const WS_BASE = `${config.server.websocketProtocol}://${SERVER_HOST}:${SERVER_PORT}/api/ws`;

export const MIN_PLAYER_NAME_LENGTH = 1;
export const MAX_PLAYER_NAME_LENGTH = 10;

export const PLACEHOLDER_PLAYER_NAME = 'Will S.';

export const PLAYER_ID_KEY = 'playerID';

export const MAX_ROOM_REQUEST_EMAIL_LENGTH = 128;
export const MAX_ROOM_REQUEST_NAME_LENGTH = 64;

export const EARLIEST_PUZZLE_DATE = '1942-02-15';

/* Prior to this date, only Sunday puzzles are available in the database. */
export const EARLIEST_EVERY_DAY_PUZZLE_DATE = '1950-09-10';

export const DAY_OF_WEEK_SUNDAY = 0;

export const COLORS = ['red', 'orange', 'yellow', 'green', 'teal', 'blue', 'cyan', 'purple', 'pink', 'gray'];

export const DEFAULT_COLOR = 'blue';

export const MAX_PASSWORD_LENGTH = 128;
export const PASSWORD_SALT_ROUNDS = 10;

export const ROOM_CODE_LENGTH = 4;
export const ROOM_CODE_CHARACTERS = 'ABCDEFGHJKLMNPQRSTVWXYZ';

export const SECONDS_PER_MINUTE = 60;
export const MINUTES_PER_HOUR = 60;
export const HOURS_PER_DAY = 24;
export const DAYS_PER_WEEK = 7;
export const DAYS_PER_MONTH = 30;
export const SECONDS_PER_HOUR = SECONDS_PER_MINUTE * MINUTES_PER_HOUR;
export const SECONDS_PER_DAY = SECONDS_PER_HOUR * HOURS_PER_DAY;
export const SECONDS_PER_WEEK = SECONDS_PER_DAY * DAYS_PER_WEEK;
export const SECONDS_PER_MONTH = SECONDS_PER_DAY * DAYS_PER_MONTH;

export const MAX_KICK_DURATION_SECONDS = SECONDS_PER_MONTH;

export const ClueDirections = {
  ACROSS: 'across',
  DOWN: 'down',
};

export const MovementDirections = {
  LEFT: 'left',
  RIGHT: 'right',
  UP: 'up',
  DOWN: 'down',
};

export const PlayerEditorModes = {
  CREATE: 'create',
  EDIT: 'edit',
};

export const Weekdays = {
  ANY_DAY: 'any day',
  MONDAY: 'Monday',
  TUESDAY: 'Tuesday',
  WEDNESDAY: 'Wednesday',
  THURSDAY: 'Thursday',
  FRIDAY: 'Friday',
  SATURDAY: 'Saturday',
  SUNDAY: 'Sunday',
};

export const DEFAULT_WEEKDAY = 'ANY_DAY';

export const EventTypes = {
  ERROR: 'error',
  /* game events */
  GAME_CREATION_FAILED: 'game_creation_failed',
  GAME_STARTING: 'game_starting',
  GAME_STARTED: 'game_started',
  GAME_SETTINGS_CHANGED: 'game_settings_changed',
  GAME_ENDED: 'game_ended',
  /* room events */
  REASSIGN_ROOM_HOST: 'reassign_room_host',
  ROOM_HOST_REASSIGNED: 'room_host_reassigned',
  /* player events */
  PLAYER_CHANGED_NAME: 'player_changed_name',
  JOIN_ROOM: 'join_room',
  JOIN_ROOM_WITH_CODE: 'join_room_with_code',
  PLAYER_JOINED_ROOM: 'player_joined_room',
  LEAVE_ROOM: 'leave_room',
  PLAYER_LEFT_ROOM: 'player_left_room',
  JOIN_GAME: 'join_game',
  PLAYER_JOINED: 'player_joined',
  PLAYER_WENT_ACTIVE: 'player_went_active',
  PLAYER_WENT_INACTIVE: 'player_went_inactive',
  /* gameplay events */
  MOVE_TO_COORDS: 'move_to_coords',
  PLAYER_MOVED_TO_COORDS: 'player_moved_to_coords',
  CHANGE_CELL_CONTENTS: 'change_cell_contents',
  PLAYER_CHANGED_CELL_CONTENTS: 'player_changed_cell_contents',
  REVEAL_WORD: 'reveal_word',
  PLAYER_REVEALED_WORD: 'player_revealed_word',
  /* host-only gameplay events */
  ABANDON_GAME: 'abandon_game',
  HOST_ABANDONED_GAME: 'host_abandoned_game',
  KICK_PLAYER: 'kick_player',
  HOST_KICKED_PLAYER: 'host_kicked_player',
  /* connection events */
  CLIENT_CONNECT: 'client_connect',
};

export const Emoji = {
  CHECK_MARK: '\u2705',
  CROSS_MARK: '\u274C',
};

export const SORT_ARROW_ASCENDING = '\u25B4';
export const SORT_ARROW_DESCENDING = '\u25BE';

export const StatusCodes = {
  /* success codes */
  NO_CONTENT: 204,
  /* client error codes */
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  CONFLICT: 409,
  /* server error codes */
  INTERNAL_SERVER_ERROR: 500,
  SERVICE_UNAVAILABLE: 503,
};

export const INVALID_PUZZLE_RANGE_START = '1978-08-14';
export const INVALID_PUZZLE_RANGE_END = '1978-11-05';
export const INVALID_PUZZLE_DATES = new Set([
  '1962-12-15',
  '1962-12-13',
  '1962-12-30',
  '1963-01-06',
  '1963-01-13',
  '1963-01-20',
  '1963-01-27',
  '1963-02-03',
  '1963-02-10',
  '1963-02-17',
  '1963-02-24',
  '1963-03-03',
  '1963-03-10',
  '1963-03-17',
  '1963-03-24',
  '1963-03-31',
  '1965-09-18',
  '1965-09-25',
  '1965-09-26',
  '1965-10-02',
  '1965-10-03',
  '1965-10-10',
  '1965-10-11',
  '1965-10-13',
  '1965-10-14',
  '1965-10-15',
  '1965-10-16',
  '1965-10-17',
  '1965-10-18',
  '1978-08-10',
  '1978-08-11',
  '1978-08-12',
]);
