import React from 'react';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import { Box, createStandaloneToast } from '@chakra-ui/react';
import { ADMIN_PLAYER_IDS } from '../../constants';
import {
  abandonGame,
  changeCellContents,
  changePlayerSettings,
  clearCurrentGame,
  clearError,
  clearRevealedWord,
  clearRoomLinkRequestSucceeded,
  clientConnect,
  createNewGameFailed,
  createNewPlayer,
  createNewRoom,
  fetchCurrentPlayer,
  fetchGame,
  fetchNewGame,
  fetchPlayer,
  fetchPlayers,
  fetchRoom,
  fetchRoomLinkRequests,
  fetchRooms,
  joinGame,
  joinRoom,
  joinRoomWithCode,
  kickPlayer,
  leaveRoom,
  moveToCoords,
  reassignRoomHost,
  requestNewRoomLink,
  resolveRoomLinkRequest,
  revealWord,
  updateAllCorrectCells,
  updateCellCorrectness,
  updateGameSettings,
  websocketConnect,
} from '../actions/action_creators';
import { getPlayerName } from '../reducers/game_reducer';
import './App.css';
import Home from './home/Home';
import Room from './Room';
import AdminDashboard from './admin/AdminDashboard';
import KickPlayerDialog from './common/players/KickPlayerDialog';
import RoomStatistics from './lobby/stats/RoomStatistics';
import PlayerEditor from './player/PlayerEditor';
import PlayerListModal from './player/list/PlayerListModal';

const CONNECTED_TOAST_ID = 'connected-to-server';
const DISCONNECTED_TOAST_ID = 'disconnected-from-server';
const RECONNECT_ATTEMPT_INTERVAL_MILLIS = 5000;

const toast = createStandaloneToast();

function mapStateToProps(state) {
  let players = {};
  Object.entries(state.players).forEach(([playerID, player]) => {
    if (player.active || playerID === state.playerID) {
      players[playerID] = player;
    }
  });
  return {...state, players: players};
}

const actionCreators = {
  abandonGame,
  changeCellContents,
  changePlayerSettings,
  clearCurrentGame,
  clearError,
  clearRevealedWord,
  clearRoomLinkRequestSucceeded,
  clientConnect,
  createNewGameFailed,
  createNewPlayer,
  createNewRoom,
  fetchCurrentPlayer,
  fetchGame,
  fetchNewGame,
  fetchPlayer,
  fetchPlayers,
  fetchRoom,
  fetchRooms,
  fetchRoomLinkRequests,
  joinGame,
  joinRoom,
  joinRoomWithCode,
  kickPlayer,
  leaveRoom,
  moveToCoords,
  reassignRoomHost,
  requestNewRoomLink,
  revealWord,
  resolveRoomLinkRequest,
  updateAllCorrectCells,
  updateCellCorrectness,
  updateGameSettings,
  websocketConnect,
};

class Connector extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      connectionIntervalID: null,
      disconnected: false,
      kickPlayerID: null,
      onPlayerEditorClose: null,
      showAdminDashboard: false,
      showKickPlayerDialog: false,
      showPlayerEditor: false,
      showPlayerList: false,
      showRoomStats: false,
    };
    this.closeAdminDashboard = this.closeAdminDashboard.bind(this);
    this.closeKickPlayerDialog = this.closeKickPlayerDialog.bind(this);
    this.closePlayerEditor = this.closePlayerEditor.bind(this);
    this.closePlayerList = this.closePlayerList.bind(this);
    this.closeRoomStats = this.closeRoomStats.bind(this);
    this.openAdminDashboard = this.openAdminDashboard.bind(this);
    this.openKickPlayerDialog = this.openKickPlayerDialog.bind(this);
    this.openPlayerEditor = this.openPlayerEditor.bind(this);
    this.openPlayerList = this.openPlayerList.bind(this);
    this.openRoomStats = this.openRoomStats.bind(this);
  }

  componentDidMount() {
    if (!this.props.connected) {
      console.log('Opening websocket connection...');
      this.props.websocketConnect();
    }

    if (this.props.playerID && !this.props.players.hasOwnProperty(this.props.playerID)) {
      this.props.fetchCurrentPlayer();
    }

    if (this.props.roomID && !this.props.room) {
      this.props.fetchRoom(this.props.roomID);
    }

    this.checkPlayerInRoom();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if ((!prevProps.connected && this.props.connected && this.props.playerID) ||
      (!prevProps.playerID && this.props.playerID && this.props.connected)) {
      console.log('Establishing connection to server...');
      this.connectAndFetchCurrentState();
    }

    if (prevProps.connected && !this.props.connected && this.props.playerID) {
      console.log('Websocket connection lost. Attempting to reconnect...');
      if (!toast.isActive(DISCONNECTED_TOAST_ID)) {
        toast({
          id: DISCONNECTED_TOAST_ID,
          position: 'top',
          title: 'Connection to server lost. Trying to reconnect...',
          status: 'error',
          isClosable: true,
        });
      }
      const intervalID = setInterval(function() {
        if (this.props.connected) {
          this.cancelConnectionInterval();
        } else {
          console.log('Attempting to reconnect...');
          this.props.websocketConnect();
        }
      }.bind(this), RECONNECT_ATTEMPT_INTERVAL_MILLIS);
      this.setState({connectionIntervalID: intervalID, disconnected: true});
    }

    if ((this.props.roomID && prevProps.roomID !== this.props.roomID) || (!prevProps.roomID && this.props.roomID && !this.props.room)) {
      this.connectAndFetchCurrentState();
    }

    if (!prevProps.room && this.props.room && this.props.room.currentGameID && this.props.room.currentGameID !== this.props.game?.gameID) {
      this.props.fetchGame(this.props.room.currentGameID);
    }

    this.checkPlayerInRoom();

    if (prevProps.playerID && !this.props.playerID) {
      this.setState({showPlayerEditor: true});
    }

    if (this.props.error && this.props.error !== prevProps.error) {
      if (!toast.isActive(this.props.error)) {
        toast({
          id: this.props.error,
          position: 'top',
          title: this.props.error,
          status: 'error',
          isClosable: true,
        });
      }
      this.props.clearError(this.props.error);
    }

    if (prevProps.room && this.props.room && prevProps.room !== this.props.room && prevProps.room.hostPlayerID !== this.props.room.hostPlayerID) {
      const playerName = (this.props.room.hostPlayerID === this.props.playerID ? 'You' : getPlayerName(this.props.room.hostPlayerID));
      const verb = (playerName === 'You' ? 'are' : 'is');
      toast({
        position: 'top',
        title: `${playerName} ${verb} now the host.`,
        status: 'info',
        isClosable: true,
      });
    }

    if (!prevProps.roomLinkRequestSucceeded && this.props.roomLinkRequestSucceeded) {
      toast({
        position: 'top',
        title: `Room link request submitted successfully.`,
        status: 'success',
        isClosable: true,
      });
      this.props.clearRoomLinkRequestSucceeded();
    }
  }

  connectAndFetchCurrentState() {
    this.props.clientConnect(this.props.playerID, this.props.roomID);
    if (this.props.roomID) {
      this.props.fetchRoom(this.props.roomID);
    }
    this.props.fetchCurrentPlayer();
  }

  checkPlayerInRoom() {
    if (this.props.connected && this.props.room && this.props.playerID && !this.props.room.playerIDs.includes(this.props.playerID)) {
      console.log('Joining room...');
      this.props.joinRoom(this.props.playerID, this.props.roomID);
    }
  }

  cancelConnectionInterval() {
    if (this.state.connectionIntervalID !== null) {
      if (this.state.disconnected && !toast.isActive(CONNECTED_TOAST_ID)) {
        toast({
          id: CONNECTED_TOAST_ID,
          position: 'top',
          title: 'Successfully connected to server.',
          status: 'success',
          isClosable: true,
        });
      }
      clearInterval(this.state.connectionIntervalID);
      this.setState({connectionIntervalID: null, disconnected: false});
    }
  }

  getPlayer(playerID) {
    return (this.props.players.hasOwnProperty(playerID) ? this.props.players[playerID] : null);
  }

  openAdminDashboard() {
    this.setState({showAdminDashboard: true});
  }

  openKickPlayerDialog(playerID) {
    this.setState({kickPlayerID: playerID, showKickPlayerDialog: true});
  }

  openPlayerEditor(onClose = null) {
    this.setState({
      onPlayerEditorClose: (onClose === null || (typeof onClose === 'function') ? onClose : null),
      showPlayerEditor: true,
    });
  }

  openPlayerList() {
    this.setState({showPlayerList: true});
  }

  openRoomStats() {
    this.setState({showRoomStats: true});
  }

  closeAdminDashboard() {
    this.setState({showAdminDashboard: false});
  }

  closeKickPlayerDialog() {
    this.setState({kickPlayerID: null, showKickPlayerDialog: false});
  }

  closePlayerEditor() {
    this.setState({showPlayerEditor: false});
    if (this.state.onPlayerEditorClose) {
      this.state.onPlayerEditorClose();
      this.setState({onPlayerEditorClose: null});
    }
  }

  closePlayerList() {
    this.setState({showPlayerList: false});
  }

  closeRoomStats() {
    this.setState({showRoomStats: false});
  }

  render() {
    const isAdmin = ADMIN_PLAYER_IDS.has(this.props.playerID);
    const urlSearchParams = new URLSearchParams(window.location.search);
    let roomCode;
    if (urlSearchParams.has('code')) {
      roomCode = urlSearchParams.get('code');
    }
    const modals = {
      adminDashboard: {
        open: this.openAdminDashboard,
        close: this.closeAdminDashboard,
      },
      kickPlayerDialog: {
        open: this.openKickPlayerDialog,
        close: this.closeKickPlayerDialog,
      },
      playerEditor: {
        open: this.openPlayerEditor,
        close: this.closePlayerEditor,
      },
      playerList: {
        open: this.openPlayerList,
        close: this.closePlayerList,
      },
      roomStats: {
        open: this.openRoomStats,
        close: this.closeRoomStats,
      },
    };
    return (
      <Box className="app">
        <Router>
          <Switch>
            <Route exact path="/">
              {this.props.room ?
                <Redirect to={`/p/${this.props.room.roomCode}`}/> :
                <Home isAdmin={isAdmin} roomCode={roomCode} modals={modals} toast={toast} {...this.props} />}
            </Route>
            <Route path="/p/:roomCode">
              <Room isAdmin={isAdmin} modals={modals} toast={toast} {...this.props} />
            </Route>
          </Switch>
        </Router>
        {this.state.showAdminDashboard && <AdminDashboard modals={modals} {...this.props} />}
        {this.state.showKickPlayerDialog && <KickPlayerDialog player={this.getPlayer(this.state.kickPlayerID)}
                                                              roomID={this.props.roomID}
                                                              modals={modals}
                                                              kickPlayer={this.props.kickPlayer} />}
        {this.state.showPlayerEditor && <PlayerEditor modals={modals} {...this.props} />}
        {this.state.showPlayerList && <PlayerListModal modals={modals} {...this.props} />}
        {this.state.showRoomStats && <RoomStatistics modals={modals} {...this.props} />}
      </Box>
    );
  }
}

export const GameConnector = connect(mapStateToProps, actionCreators)(Connector);
