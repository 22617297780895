import React from 'react';
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
} from '@chakra-ui/react';
import PlayerList from './PlayerList';

export default function PlayerListModal(props) {
  return (
    <Modal isOpen={true} closeOnEsc={true} closeOnOverlayClick={true}
           onClose={props.modals.playerList.close} size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody p={0}>
          <PlayerList currentPlayerID={props.playerID} {...props} />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
