import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalOverlay, Text } from '@chakra-ui/react';

export default function NotesModal(props) {
  return (
    <Modal isOpen={props.isOpen} closeOnEsc={true} closeOnOverlayClick={true} onClose={props.onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody p={5}>
          <Text align="center">
            {ReactHtmlParser(props.notes)}
          </Text>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
