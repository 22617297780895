import React from 'react';
import { Badge, Popover, PopoverArrow, PopoverBody, PopoverContent, PopoverTrigger } from '@chakra-ui/react';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import ActionIcon from '../../common/ActionIcon';
import Menu from './Menu';

export default function MenuButton(props) {
  const [isOpen, setIsOpen] = React.useState(false);
  const open = () => setIsOpen(!isOpen);
  const close = () => setIsOpen(false);
  const title = 'Menu';
  return (
    <Popover isOpen={isOpen} onClose={close}>
      <PopoverTrigger>
        <Badge variant="solid" bg="white" color="black" borderRadius="lg" boxShadow="dark-lg" px={3} py={2} fontSize="xl"
               userSelect="none" title={title} className="hover-pointer" position="fixed" zIndex="1000" top="5" right="5"
               onClick={open}>
          <ActionIcon id="menu" icon={faBars} title={title} onClick={open} />
        </Badge>
      </PopoverTrigger>
      <PopoverContent p={0} w="auto">
        <PopoverArrow />
        <PopoverBody p={0}>
          <Menu closePopover={close} {...props} />
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
}
