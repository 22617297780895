import React from 'react';
import { Text } from '@chakra-ui/react';
import {formatTimeDeltaAsClock, getTimeDelta} from '../../utils';

export default function Timer(props) {
  return (
    <Text fontSize="4xl" fontFamily="Share Tech Mono" cursor="default" userSelect="none" {...props}>
      {formatTimeDeltaAsClock(getTimeDelta(props.seconds || 0))}
    </Text>
  );
}
