import React from 'react';
import moment from 'moment';
import { Box, Heading } from '@chakra-ui/react';
import { ClueDirections, MovementDirections } from '../../../constants';
import {
  checkSquare,
  EventContext,
  formatList,
  getCoordinatesOfCellWithNumber,
  getGridInfo,
  range,
} from '../../../commonUtils';
import { isElementInViewport } from '../../utils';
import Puzzle from './puzzle/Puzzle';
import MenuButton from './menu/MenuButton';
import GameSummary from './GameSummary';
import RebusDialog from './RebusDialog';
import Timer from './Timer';

export default class Game extends React.Component {
  constructor(props) {
    super(props);

    let timerSeconds = 0;
    if (props.game) {
      const createdTime = moment(props.game.createdTime);
      timerSeconds = Math.floor(moment().diff(createdTime) / 1000);
    }

    this.state = {
      activeDirection: ClueDirections.ACROSS,
      reveal: false,
      showRebusDialog: false,
      timerUpdater: null,
      timerSeconds: timerSeconds,
    };

    this.changeCellContents = this.changeCellContents.bind(this);
    this.checkPuzzle = this.checkPuzzle.bind(this);
    this.checkSelectedSquare = this.checkSelectedSquare.bind(this);
    this.checkSelectedWord = this.checkSelectedWord.bind(this);
    this.closeRebusDialog = this.closeRebusDialog.bind(this);
    this.handleClueClick = this.handleClueClick.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleKeyUp = this.handleKeyUp.bind(this);
    this.isCellHighlighted = this.isCellHighlighted.bind(this);
    this.moveToCoords = this.moveToCoords.bind(this);
    this.openRebusDialog = this.openRebusDialog.bind(this);
    this.revealSelectedSquare = this.revealSelectedSquare.bind(this);
    this.revealSelectedWord = this.revealSelectedWord.bind(this);
    this.toggleActiveDirection = this.toggleActiveDirection.bind(this);

    /* pre-compute some helpful information about each cell */
    this.acrossNumbers = new Set(props.game.puzzle.clues.across.map(clue => clue.index));
    this.downNumbers = new Set(props.game.puzzle.clues.down.map(clue => clue.index));
    this.gridInfo = getGridInfo(props.game.puzzle.grid, props.game.puzzle.clues);
  }

  componentDidMount() {
    this.checkPlayerInGame();
    this.setState({
      timerUpdater: setInterval(function() {
        this.setState({timerSeconds: this.state.timerSeconds + 1});
      }.bind(this), 1000),
    });
    document.addEventListener('keydown', this.handleKeyDown);
    document.addEventListener('keyup', this.handleKeyUp);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!prevProps.game && this.props.game && !this.props.connected) {
      console.log('Game loaded. Opening websocket connection...');
      this.props.websocketConnect();
    }

    this.checkPlayerInGame();

    if ((prevProps.game.playerCoordinates[this.props.playerID] !== this.props.game.playerCoordinates[this.props.playerID]) ||
        (prevState.activeDirection !== this.state.activeDirection)) {
      this.scrollSelectedClueIntoView();
    }

    if (this.props.game && prevProps.players !== this.props.players &&
        Object.keys(this.props.players).length > Object.keys(prevProps.players).length) {
      let newPlayers = Object.entries(this.props.players).filter(([playerID, _]) =>
        playerID !== this.props.playerID && !prevProps.players.hasOwnProperty(playerID)
      ).map(([_, player]) => player.name);
      if (newPlayers.length) {
        this.props.toast({
          position: 'top',
          title: `${formatList(newPlayers.sort())} joined the game.`,
          status: 'info',
          isClosable: true,
        });
      }
    } else if (this.props.game && prevProps.players !== this.props.players &&
        Object.keys(this.props.players).length < Object.keys(prevProps.players).length) {
      let leavingPlayers = Object.entries(prevProps.players).filter(([playerID, _]) =>
        playerID !== this.props.playerID && !this.props.players.hasOwnProperty(playerID)
      ).map(([_, player]) => player.name);
      if (leavingPlayers.length) {
        this.props.toast({
          position: 'top',
          title: `${formatList(leavingPlayers.sort())} left the game.`,
          status: 'info',
          isClosable: true,
        });
      }
    }

    if (!prevProps.game.finishedTime && this.props.game.finishedTime) {
      if (this.state.timerUpdater) {
        clearInterval(this.state.timerUpdater);
      }
      this.setState({
        timerUpdater: null,
        timerSeconds: Math.floor(moment(this.props.game.finishedTime).diff(this.props.game.createdTime) / 1000),
      });
    }

    if (!prevProps.revealedWord && this.props.revealedWord) {
      const { playerID, direction, index, answer } = this.props.revealedWord;
      if (playerID !== this.props.playerID) {
        this.props.toast({
          position: 'top',
          title: `${this.getPlayerName(playerID)} revealed ${index}-${direction.toTitleCase()} (${answer}).`,
          status: 'info',
          isClosable: true,
        });
      }
      this.props.clearRevealedWord();
      this.resetCellCorrectnessForWord(direction, index);
    }
  }

  componentWillUnmount() {
    if (this.state.timerUpdater) {
      clearInterval(this.state.timerUpdater);
      this.setState({timerUpdater: null});
    }
    document.removeEventListener('keydown', this.handleKeyDown);
    document.removeEventListener('keyup', this.handleKeyUp);
  }

  grid() {
    return this.props.game.puzzle.grid;
  }

  coords() {
    return this.props.game?.playerCoordinates[this.props.playerID] || [0, 0];
  }

  rows() {
    return this.props.game.puzzle.size.rows;
  }

  columns() {
    return this.props.game.puzzle.size.columns;
  }

  getEventContext() {
    return new EventContext(this.props.roomID, this.props.game.gameID, this.props.playerID);
  }

  checkPlayerInGame() {
    if (this.props.connected && this.props.game && this.props.playerID && !this.props.game.playerIDs.includes(this.props.playerID)) {
      console.log('Joining game...');
      this.props.joinGame(this.getEventContext());
    }
  }

  playerIsHost() {
    return (!!this.props.playerID && !!this.props.room && this.props.playerID === this.props.room.hostPlayerID);
  }

  getPlayerName(playerID) {
    return this.props.players[playerID]?.name;
  }

  getSelectedClueIndex() {
    const [row, column] = this.coords();
    return this.gridInfo.clueNumbers[this.state.activeDirection][row][column];
  }

  getCoordinatesOfCellWithNumber(number) {
    return getCoordinatesOfCellWithNumber(this.props.game.puzzle, number);
  }

  isCellHighlighted(i, j, isSelected) {
    return (!isSelected && (this.gridInfo.clueNumbers[this.state.activeDirection][i][j] === this.getSelectedClueIndex()));
  }

  oppositeDirection() {
    return (this.state.activeDirection === ClueDirections.ACROSS ? ClueDirections.DOWN : ClueDirections.ACROSS);
  }

  toggleActiveDirection() {
    this.setState({activeDirection: this.oppositeDirection()});
  }

  moveToCoords(coords) {
    this.props.moveToCoords(EventContext.fromProps(this.props), coords);
  }

  changeCellContents(text) {
    this.props.changeCellContents(EventContext.fromProps(this.props), this.coords(), this.state.activeDirection, text.toUpperCase());
  }

  handleClueClick(number, direction) {
    const coords = this.getCoordinatesOfCellWithNumber(number);
    if (coords) {
      this.props.moveToCoords(EventContext.fromProps(this.props), coords);
      if (direction !== this.state.activeDirection) {
        this.setState({activeDirection: this.oppositeDirection()});
      }
    }
  }

  handleKeyDown(event) {
    const key = event.key.toLowerCase();
    if (['arrowleft', 'arrowright', 'arrowup', 'arrowdown', 'tab', ' '].includes(key)) {
      event.preventDefault();
      event.stopPropagation();
    }/* else if (key === '`') {
      this.setState({reveal: true});
    }*/
  }

  handleKeyUp(event) {
    if (this.state.showRebusDialog) {
      return;
    }

    const key = event.key.toLowerCase();
    const [row, column] = this.coords();

    if (key === ' ') {
      event.preventDefault();
      this.toggleActiveDirection();
    } else if (key === 'arrowleft' && column > 0) {
      const coords = this.gridInfo.coordinatesOfNextNonBlankCell[MovementDirections.LEFT][row][column];
      if (coords) {
        event.preventDefault();
        this.props.moveToCoords(EventContext.fromProps(this.props), coords);
      }
    } else if (key === 'arrowright' && column < this.columns() - 1) {
      const coords = this.gridInfo.coordinatesOfNextNonBlankCell[MovementDirections.RIGHT][row][column];
      if (coords) {
        event.preventDefault();
        this.props.moveToCoords(EventContext.fromProps(this.props), coords);
      }
    } else if (key === 'arrowup' && row > 0) {
      const coords = this.gridInfo.coordinatesOfNextNonBlankCell[MovementDirections.UP][row][column];
      if (coords) {
        event.preventDefault();
        this.props.moveToCoords(EventContext.fromProps(this.props), coords);
      }
    } else if (key === 'arrowdown' && row < this.rows() - 1) {
      const coords = this.gridInfo.coordinatesOfNextNonBlankCell[MovementDirections.DOWN][row][column];
      if (coords) {
        event.preventDefault();
        this.props.moveToCoords(EventContext.fromProps(this.props), coords);
      }
    } else if (key === 'enter') {
        this.openRebusDialog();
    }/* else if (key === '`') {
      event.preventDefault();
      this.setState({reveal: false});
    }*/ else if (key === 'tab') {
      event.preventDefault();
      const direction = (this.state.activeDirection === ClueDirections.ACROSS ?
        (event.shiftKey ? MovementDirections.LEFT : MovementDirections.RIGHT) :
        (event.shiftKey ? MovementDirections.UP : MovementDirections.DOWN));
      const coords = this.gridInfo.coordinatesOfNextNumberedCell[direction][row][column];
      if (coords) {
        event.preventDefault();
        this.props.moveToCoords(EventContext.fromProps(this.props), coords);
      }
    } else if (key === 'backspace') {
      event.preventDefault();
      this.changeCellContents('');
      if (!this.props.correctCells[row][column]) {
        this.props.updateCellCorrectness([row, column], true);
      }
    } else if ('abcdefghijklmnopqrstuvwxyz0123456789'.includes(key)) {
      event.preventDefault();
      this.changeCellContents(key);
      if (!this.props.correctCells[row][column]) {
        this.props.updateCellCorrectness([row, column], true);
      }
    }
  }

  scrollSelectedClueIntoView() {
    const clueID = `${this.getSelectedClueIndex()}-${this.state.activeDirection}`;
    const element = document.getElementById(clueID);
    if (element && !isElementInViewport(element)) {
      element.scrollIntoView({behavior: 'smooth'});
    }
  }

  openRebusDialog() {
    this.setState({showRebusDialog: true});
  }

  closeRebusDialog() {
    this.setState({showRebusDialog: false});
  }

  checkSquare(i, j, emptyIsValid = true) {
    return checkSquare(this.props.game.puzzle, i, j, emptyIsValid);
  }

  checkSelectedSquare() {
    const [row, column] = this.coords();
    if (!this.checkSquare(row, column) && this.props.correctCells[row][column]) {
      this.props.updateCellCorrectness([row, column], false);
    }
  }

  checkSelectedWord() {
    const [row, column] = this.coords();
    const correctCells = [...this.props.correctCells];
    const currentNumber = this.gridInfo.clueNumbers[this.state.activeDirection][row][column];
    const startingCoords = this.getCoordinatesOfCellWithNumber(currentNumber);
    if (this.state.activeDirection === ClueDirections.ACROSS) {
      const i = startingCoords[0];
      let j = startingCoords[1];
      while (true) {
        if (j >= this.props.game.puzzle.size.columns || this.grid()[i][j].hasOwnProperty('blank')) {
          break;
        }
        if (!this.checkSquare(i, j) && this.props.correctCells[i][j]) {
          correctCells[i][j] = false;
        }
        j += 1;
      }
    } else {
      const j = startingCoords[1];
      let i = startingCoords[0];
      while (true) {
        if (i >= this.props.game.puzzle.size.rows || this.grid()[i][j].hasOwnProperty('blank')) {
          break;
        }
        if (!this.checkSquare(i, j) && this.props.correctCells[i][j]) {
          correctCells[i][j] = false;
        }
        i += 1;
      }
    }
    if (correctCells !== this.props.correctCells) {
      this.props.updateAllCorrectCells(correctCells);
    }
  }

  checkPuzzle() {
    const correctCells = [...this.props.correctCells];
    range(this.rows()).forEach(i => {
      range(this.columns()).forEach(j => {
        if (!this.checkSquare(i, j) && this.props.correctCells[i][j]) {
          correctCells[i][j] = false;
        }
      });
    });
    if (correctCells !== this.props.correctCells) {
      console.log('updating correct cells');
      this.props.updateAllCorrectCells(correctCells);
    }
  }

  resetCellCorrectnessForWord(direction, index) {
    const correctCells = [...this.props.correctCells];
    const startingCoords = this.getCoordinatesOfCellWithNumber(index);
    if (direction === ClueDirections.ACROSS) {
      const i = startingCoords[0];
      let j = startingCoords[1];
      while (true) {
        if (j >= this.props.game.puzzle.size.columns || this.grid()[i][j].hasOwnProperty('blank')) {
          break;
        }
        if (!this.props.correctCells[i][j]) {
          correctCells[i][j] = true;
        }
        j += 1;
      }
    } else {
      const j = startingCoords[1];
      let i = startingCoords[0];
      while (true) {
        if (i >= this.props.game.puzzle.size.rows || this.grid()[i][j].hasOwnProperty('blank')) {
          break;
        }
        if (!this.props.correctCells[i][j]) {
          correctCells[i][j] = true;
        }
        i += 1;
      }
    }
    if (correctCells !== this.props.correctCells) {
      this.props.updateAllCorrectCells(correctCells);
    }
  }

  revealSelectedSquare() {
    const [row, column] = this.coords();
    if (!this.checkSquare(row, column, false)) {
      this.changeCellContents(this.grid()[row][column].text);
      if (!this.props.correctCells[row][column]) {
        this.props.updateCellCorrectness([row, column], true);
      }
    }
  }

  revealSelectedWord() {
    const [row, column] = this.coords();
    const currentNumber = this.gridInfo.clueNumbers[this.state.activeDirection][row][column];
    this.props.revealWord(EventContext.fromProps(this.props), this.state.activeDirection, currentNumber);
  }

  render() {
    const { gameID, puzzle } = this.props.game;
    const coords = this.coords();
    const gameState = {
      roomID: this.props.roomID,
      gameID: gameID,
      playerID: this.props.playerID,
      playerIsAdmin: this.props.isAdmin,
      playerIsHost: this.playerIsHost(),
      playerIsOwner: (!!this.props.playerID && !!this.props.room && this.props.playerID === this.props.room.ownerPlayerID),
      selectedClueIndex: this.getSelectedClueIndex(),
      selectedCoordinates: coords,
    };
    let heading;
    if (puzzle.subtitle) {
      heading = (
        <React.Fragment>
          <Heading fontSize="2xl" fontFamily="Alfa Slab One" pt={3}>{puzzle.title}</Heading>
          <Heading fontSize="md">{puzzle.subtitle}</Heading>
        </React.Fragment>
      );
    } else {
      heading = <Heading fontSize="3xl" fontFamily="Alfa Slab One" pt={3}>{puzzle.title}</Heading>;
    }
    return (
      <React.Fragment>
        <Timer seconds={this.state.timerSeconds} position="fixed" zIndex="1000" top="0" left="100" pt={3} />
        <MenuButton gameState={gameState}
                    puzzle={puzzle}
                    modals={this.props.modals}
                    abandonGame={this.props.abandonGame}
                    leaveRoom={this.props.leaveRoom}
                    checkPuzzle={this.checkPuzzle}
                    checkSelectedSquare={this.checkSelectedSquare}
                    checkSelectedWord={this.checkSelectedWord}
                    revealSelectedSquare={this.revealSelectedSquare}
                    revealSelectedWord={this.revealSelectedWord} />
        <Box height="8vh" pt={2}>
          {heading}
        </Box>
        <Puzzle gameState={gameState}
                game={this.props.game}
                correctCells={this.props.correctCells}
                players={this.props.players}
                handleClueClick={this.handleClueClick}
                isCellHighlighted={this.isCellHighlighted}
                moveToCoords={this.moveToCoords}
                toggleActiveDirection={this.toggleActiveDirection}
                {...this.state} />
        {this.state.showRebusDialog && <RebusDialog close={this.closeRebusDialog}
                                                    initialValue={this.grid()[coords[0]][coords[1]].currentText}
                                                    changeCellContents={this.changeCellContents} />}
        {this.props.game?.finishedTime && <GameSummary {...this.props} />}
      </React.Fragment>
    );
  }
}
