/*
 NOTE: This file must be manually kept in sync with server/src/commonUtils.mjs.
 I regret my decisions, and JavaScript is a horrible, horrible language.
 */

import moment from 'moment';
import '@gouch/to-title-case';
import {
  ClueDirections,
  DAY_OF_WEEK_SUNDAY,
  EARLIEST_EVERY_DAY_PUZZLE_DATE,
  EARLIEST_PUZZLE_DATE,
  INVALID_PUZZLE_DATES,
  INVALID_PUZZLE_RANGE_END,
  INVALID_PUZZLE_RANGE_START,
  MovementDirections,
} from './constants';

/* anything@anything.anything */
const EMAIL_REGEX = /\S+@\S+\.\S+/;

export class WebsocketEvent {
  constructor(eventType, payload) {
    this.eventType = eventType;
    this.payload = payload;
  }
}

export class EventContext {
  static fromProps(props) {
    const roomID = props.gameState?.roomID || props.roomID;
    const gameID = props.gameState?.gameID || props.game?.gameID || props.gameID;
    const playerID = props.gameState?.playerID || props.player?.playerID || props.playerID;
    return new EventContext(roomID, gameID, playerID);
  }

  constructor(roomID, gameID, playerID) {
    this.roomID = roomID;
    this.gameID = gameID;
    if (playerID) {
      this.playerID = playerID;
    }
  }
}

export function range(n) {
  return [...Array(n).keys()];
}

export function randomChoice(values) {
  return values[Math.floor(Math.random() * values.length)];
}

export function isSuperset(set, subset) {
  for (let elem of subset) {
    if (!set.has(elem)) {
      return false;
    }
  }
  return true;
}

export function formatList(items) {
  let result = '';
  items.forEach((item, i) => {
    result += item;
    if (i < items.length - 2) {
      result += ', ';
    } else if (i === items.length - 2) {
      result += ' and ';
    }
  });
  return result;
}

export function comparePlayerNames(player1, player2) {
  return player1.name.toLowerCase().localeCompare(player2.name.toLowerCase());
}

export function validateEmail(email) {
  return EMAIL_REGEX.test(email);
}

export function getISODateString(date) {
  const timezoneOffset = new Date().getTimezoneOffset() * 60_000;
  date = new Date(date - timezoneOffset);
  return date.toISOString().substring(0, 10);
}

export function isValidPuzzleDate(date) {
  date = moment(date);
  if (!date) {
    return false;
  }
  const now = moment();
  if (date < moment(EARLIEST_PUZZLE_DATE) || date > now) {
    return false;
  }
  if (date < moment(EARLIEST_EVERY_DAY_PUZZLE_DATE)) {
    return (date.day() === DAY_OF_WEEK_SUNDAY);
  }
  if (date >= moment(INVALID_PUZZLE_RANGE_START) && date <= moment(INVALID_PUZZLE_RANGE_END)) {
    return false;
  }
  const isoDateString = getISODateString(date);
  for (let invalidDate of INVALID_PUZZLE_DATES) {
    if (isoDateString === invalidDate) {
      return false;
    }
  }
  return true;
}

export function checkSquare(puzzle, i, j, emptyIsValid = true) {
  const cell = puzzle.grid[i][j];
  if (emptyIsValid) {
    return !(cell.currentText && cell.text && cell.currentText.toLowerCase() !== cell.text.toLowerCase());
  }
  return (cell.currentText && cell.text && cell.currentText.toLowerCase() === cell.text.toLowerCase());
}

export function getCoordinatesOfCellWithNumber(puzzle, number) {
  for (let i = 0; i < puzzle.size.rows; i++) {
    for (let j = 0; j < puzzle.size.columns; j++) {
      if (puzzle.grid[i][j].number === number) {
        return [i, j];
      }
    }
  }
  return null;
}

export function getGridInfo(grid, clues) {
  const acrossNumbers = new Set(clues.across.map(clue => clue.index));
  const downNumbers = new Set(clues.down.map(clue => clue.index));
  let gridInfo = {
    coordinatesOfNextNonBlankCell: {},
    coordinatesOfNextNumberedCell: {},
  };
  Object.values(MovementDirections).forEach(direction => {
    gridInfo.coordinatesOfNextNonBlankCell[direction] = [];
    gridInfo.coordinatesOfNextNumberedCell[direction] = [];
    range(grid.length).forEach(i => {
      gridInfo.coordinatesOfNextNonBlankCell[direction][i] = [];
      gridInfo.coordinatesOfNextNumberedCell[direction][i] = [];
      range(grid[0].length).forEach(j => {
        gridInfo.coordinatesOfNextNonBlankCell[direction][i].push(getNextNonBlankCellCoordinates(grid, direction, i, j));
        gridInfo.coordinatesOfNextNumberedCell[direction][i].push(
          getNextNumberedCellCoordinates(grid, acrossNumbers, downNumbers, direction, i, j)
        );
      });
    });
  });
  gridInfo.clueNumbers = {
    [ClueDirections.ACROSS]: computeClueNumbersAcross(grid, acrossNumbers),
    [ClueDirections.DOWN]: computeClueNumbersDown(grid, downNumbers),
  };
  return gridInfo;
}

function getNextNonBlankCellCoordinates(grid, direction, i, j) {
  const rows = grid.length;
  const columns = grid[0].length;
  if (direction === MovementDirections.LEFT && j > 0) {
    let index = j - 1;
    while (grid[i][index].hasOwnProperty('blank') && index > 0) {
      index -= 1;
    }
    if (index >= 0 && !grid[i][index].hasOwnProperty('blank')) {
      return [i, index];
    }
  } else if (direction === MovementDirections.RIGHT && j < columns - 1) {
    let index = j + 1;
    while (grid[i][index].hasOwnProperty('blank') && index < columns - 1) {
      index += 1;
    }
    if (index < columns && !grid[i][index].hasOwnProperty('blank')) {
      return [i, index];
    }
  } else if (direction === MovementDirections.UP && i > 0) {
    let index = i - 1;
    while (grid[index][j].hasOwnProperty('blank') && index > 0) {
      index -= 1;
    }
    if (index >= 0 && !grid[index][j].hasOwnProperty('blank')) {
      return [index, j];
    }
  } else if (direction === MovementDirections.DOWN && i < rows - 1) {
    let index = i + 1;
    while (grid[index][j].hasOwnProperty('blank') && index < rows - 1) {
      index += 1;
    }
    if (index < rows && !grid[index][j].hasOwnProperty('blank')) {
      return [index, j];
    }
  }
  return null;
}

function getNextNumberedCellCoordinates(grid, acrossNumbers, downNumbers, direction, i, j) {
  const rows = grid.length;
  const columns = grid[0].length;
  if (direction === MovementDirections.LEFT) {
    let index = -1;
    if (j > 0) {
      index = j - 1;
      while (index > 0 && (!grid[i][index].hasOwnProperty('number') || !acrossNumbers.has(grid[i][index].number))) {
        index -= 1;
      }
    }
    if (index >= 0 && grid[i][index].hasOwnProperty('number') && acrossNumbers.has(grid[i][index].number)) {
      return [i, index];
    } else if (i === 0) {
      return (grid[rows - 1][columns - 1].hasOwnProperty('blank') ?
        getNextNumberedCellCoordinates(grid, acrossNumbers, downNumbers, direction, rows - 1, columns - 1) :
        [rows - 1, columns - 1]);
    } else {
      return getNextNumberedCellCoordinates(grid, acrossNumbers, downNumbers, direction, i - 1, columns);
    }
  } else if (direction === MovementDirections.RIGHT) {
    let index = columns;
    if (j < columns - 1) {
      index = j + 1;
      while (index < columns - 1 && (!grid[i][index].hasOwnProperty('number') || !acrossNumbers.has(grid[i][index].number))) {
        index += 1;
      }
    }
    if (index < columns && grid[i][index].hasOwnProperty('number') && acrossNumbers.has(grid[i][index].number)) {
      return [i, index];
    } else if (i === rows - 1) {
      return (grid[0][0].hasOwnProperty('blank') ?
        getNextNumberedCellCoordinates(grid, acrossNumbers, downNumbers, direction, 0, 0) :
        [0, 0]
      );
    } else {
      return getNextNumberedCellCoordinates(grid, acrossNumbers, downNumbers, direction, i + 1, -1);
    }
  } else if (direction === MovementDirections.UP) {
    let index = -1;
    if (i > 0) {
      index = i - 1;
      while (index > 0 && (!grid[index][j].hasOwnProperty('number') || !downNumbers.has(grid[index][j].number))) {
        index -= 1;
      }
    }
    if (index >= 0 && grid[index][j].hasOwnProperty('number') && downNumbers.has(grid[index][j].number)) {
      return [index, j];
    } else if (j === 0) {
      return (grid[rows - 1][columns - 1].hasOwnProperty('number') ?
        [rows - 1, columns - 1] :
        getNextNumberedCellCoordinates(grid, acrossNumbers, downNumbers, direction, rows - 1, columns - 1));
    } else {
      return getNextNumberedCellCoordinates(grid, acrossNumbers, downNumbers, direction, rows, j - 1);
    }
  } else if (direction === MovementDirections.DOWN && i < rows - 1) {
    let index = rows;
    if (i < rows - 1) {
      index = i + 1;
      while (index < rows - 1 && (!grid[index][j].hasOwnProperty('number') || !downNumbers.has(grid[index][j].number))) {
        index += 1;
      }
    }
    if (index < rows && grid[index][j].hasOwnProperty('number') && downNumbers.has(grid[index][j].number)) {
      return [index, j];
    } else if (j === columns - 1) {
      return (grid[0][0].hasOwnProperty('number') ?
        [0, 0] :
        getNextNumberedCellCoordinates(grid, acrossNumbers, downNumbers, direction, 0, 0));
    } else {
      return getNextNumberedCellCoordinates(grid, acrossNumbers, downNumbers, direction, -1, j + 1);
    }
  }
  return null;
}

function computeClueNumbersAcross(grid, acrossNumbers) {
  const rows = grid.length;
  const columns = grid[0].length;
  let currentNumber = 0;
  let numbers = [];
  range(rows).forEach(i => {
    let row = [];
    range(columns).forEach(j => {
      const cell = grid[i][j];
      if (cell.hasOwnProperty('blank')) {
        row.push(0);
      } else {
        if (cell.hasOwnProperty('number') && acrossNumbers.has(cell.number)) {
          currentNumber = cell.number;
        }
        row.push(currentNumber);
      }
    });
    numbers.push(row);
  });
  return numbers;
}

function computeClueNumbersDown(grid, downNumbers) {
  const rows = grid.length;
  const columns = grid[0].length;
  let currentNumber = 0;
  let numbers = [];
  range(rows).forEach(i => {
    numbers[i] = [];
    range(columns).forEach(j => {
      numbers[i][j] = 0;
    });
  });
  range(columns).forEach(j => {
    range(rows).forEach(i => {
      const cell = grid[i][j];
      if (!cell.hasOwnProperty('blank')) {
        if (cell.hasOwnProperty('number') && downNumbers.has(cell.number)) {
          currentNumber = cell.number;
        }
        numbers[i][j] = currentNumber;
      }
    });
  });
  return numbers;
}
