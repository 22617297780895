import React from 'react';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Flex,
  Input,
} from '@chakra-ui/react';

export default class RebusDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      inputRef: React.createRef(),
      value: props.initialValue || '',
    };
    this.handleKeyUp = this.handleKeyUp.bind(this);
    this.handleValueChanged = this.handleValueChanged.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    document.addEventListener('keyup', this.handleKeyUp);
  }

  componentWillUnmount() {
    document.removeEventListener('keyup', this.handleKeyUp);
  }

  handleKeyUp(event) {
    const key = event.key.toLowerCase();
    if (key === 'enter') {
      this.handleSubmit();
    }
  }

  handleValueChanged(event) {
    this.setState({value: event.target.value.toUpperCase()});
  }

  handleSubmit() {
    if (this.state.value) {
      this.props.changeCellContents(this.state.value);
      this.props.close();
    }
  }

  render() {
    return (
      <AlertDialog isOpen={true} leastDestructiveRef={this.state.inputRef} onClose={this.props.close}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="2xl" fontWeight="bold" pb={1}>Enter Rebus</AlertDialogHeader>
            <AlertDialogBody fontSize="xl" pb={2}>
              <Flex align="center" justify="center" direction="row" my={1}>
                <Input ref={this.state.inputRef} focusBorderColor="blue.400" w="80%" value={this.state.value}
                       onChange={this.handleValueChanged} />
              </Flex>
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button onClick={this.props.close}>Cancel</Button>
              <Button colorScheme="blue" onClick={this.handleSubmit} ml={3}>Enter</Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    );
  }
}
