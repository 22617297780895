import React from 'react';
import { Box, GridItem, SimpleGrid } from '@chakra-ui/react';
import { comparePlayerNames } from '../../../../commonUtils';
import {ClueDirections, DEFAULT_COLOR} from '../../../../constants';
import GridRow from '../../common/GridRow';
import Cell from './Cell';
import ClueList from './ClueList';

export default function Puzzle(props) {
  const { playerCoordinates, puzzle } = props.game;
  const { clues, grid } = puzzle;
  const { rows, columns } = puzzle.size;

  const playerColor = props.players[props.gameState.playerID]?.color || DEFAULT_COLOR;

  const rowHeight = `${Math.round(100 / rows)}%`;
  const cellWidth = `${Math.round(100 / columns)}%`;
  const isCramped = (rows > 15);

  return (
    <GridRow cols={4} gap={8} m={4} height="90vh" width="100%">
      <GridItem height="90vh" maxH="90vh" pl={3}>
        <ClueList clues={clues.across} onClick={props.handleClueClick} selectedClueIndex={props.gameState.selectedClueIndex}
                  direction={ClueDirections.ACROSS} isActive={props.activeDirection === ClueDirections.ACROSS} playerColor={playerColor} />
      </GridItem>
      <GridItem colSpan={2}>
        <Box width="100%" height="85vh">
          {grid.map((row, i) => {
            return (
              <SimpleGrid columns={columns} key={i} width="100%" height={rowHeight}>
                {row.map((cell, j) => {
                  const isIncorrect = (props.correctCells ? !props.correctCells[i][j] : false);
                  const isSelected = (i === props.gameState.selectedCoordinates[0] && j === props.gameState.selectedCoordinates[1]);
                  const isHighlighted = props.isCellHighlighted(i, j, isSelected);
                  const occupyingPlayers = Object.entries(playerCoordinates || {}).filter(([playerID, coords]) =>
                    playerID !== props.gameState.playerID && coords[0] === i && coords[1] === j && props.players[playerID]?.active
                  ).map(([playerID, _]) => props.players[playerID]).sort(comparePlayerNames);
                  const clickHandler = () => {
                    if (cell.hasOwnProperty('blank')) {
                      return;
                    }
                    if (isSelected) {
                      props.toggleActiveDirection();
                    } else {
                      props.moveToCoords([i, j]);
                    }
                  };
                  return <Cell key={j} cell={cell} coords={[i, j]} occupyingPlayers={occupyingPlayers} gameState={props.gameState}
                               isCramped={isCramped} isSelected={isSelected} isHighlighted={isHighlighted} isIncorrect={isIncorrect}
                               reveal={props.reveal} onClick={clickHandler} width={cellWidth} playerColor={playerColor} />;
                })}
              </SimpleGrid>
            );
          })}
        </Box>
      </GridItem>
      <GridItem height="90vh" maxH="90vh" pr={3}>
        <ClueList clues={clues.down} onClick={props.handleClueClick} selectedClueIndex={props.gameState.selectedClueIndex}
                  direction={ClueDirections.DOWN} isActive={props.activeDirection === ClueDirections.DOWN} playerColor={playerColor} />
      </GridItem>
    </GridRow>
  );
}
