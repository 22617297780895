/*
 NOTE: This file must be manually kept in sync with server/src/models/player.mjs.
 I regret my decisions, and JavaScript is a horrible, horrible language.
 */

import { MAX_PLAYER_NAME_LENGTH, MIN_PLAYER_NAME_LENGTH } from '../constants';

export function validatePlayerName(name) {
  return (!!name && name?.length >= MIN_PLAYER_NAME_LENGTH && name?.length <= MAX_PLAYER_NAME_LENGTH);
}
