import React from 'react';
import { Box, FormLabel, GridItem, SimpleGrid } from '@chakra-ui/react';
import { range } from '../../../../commonUtils';
import { COLORS } from '../../../../constants';
import GridRow from '../../common/GridRow';

const COLORS_PER_ROW = 5;
const NUM_ROWS = Math.ceil(COLORS.length / COLORS_PER_ROW);

export default function PlayerColorSetting(props) {
  const rows = range(NUM_ROWS).map(i => {
    const choices = range(COLORS_PER_ROW).map(j => {
      const index = (i * COLORS_PER_ROW) + j;
      const color = COLORS[index];
      let classes = 'hover-pointer color-sample';
      if (color === props.selectedColor) {
        classes += ' color-sample-selected';
      }
      return (
        <Box key={j} bg={`${color}.400`} className={classes} borderRadius="md" mb={3} mx={3} title={color}
             onClick={() => props.onChange(color)}>
        </Box>
      );
    });
    return <SimpleGrid key={i} columns={COLORS_PER_ROW}>{choices}</SimpleGrid>;
  });
  return (
    <GridRow cols={6} my={5}>
      <GridItem my={1}>
        <FormLabel fontWeight="bold" fontSize="2xl" mb={0}>Color</FormLabel>
      </GridItem>
      <GridItem colSpan={5}>
        {rows}
      </GridItem>
    </GridRow>
  );
}
