import React from 'react';
import {
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay, StatGroup,
} from '@chakra-ui/react';
import { formatTimeDelta, formatTimeDeltaAsClock, getTimeDelta } from '../../../utils';
import Card from '../../common/card/Card';
import RoomStatistic from './RoomStatistic';

const GROUP_PADDING = 10;

export default class RoomStatistics extends React.Component {
  componentDidMount() {
    this.props.fetchRoom(this.props.roomID);
  }

  render() {
    const {
      averageCompletionTimeInSeconds,
      currentCompletionStreak,
      puzzlesCompleted,
      puzzlesPlayed,
    } = this.props.room.stats;
    const completionPercentage = Math.round((puzzlesPlayed === 0 ? 0 : (puzzlesCompleted / puzzlesPlayed)) * 100);
    let solveTime = 'N/A';
    let solveTimeHelpText = null;
    if (puzzlesCompleted) {
      const averageCompletionTimeDelta = getTimeDelta(averageCompletionTimeInSeconds);
      solveTime = formatTimeDeltaAsClock(averageCompletionTimeDelta, false);
      solveTimeHelpText = formatTimeDelta(averageCompletionTimeDelta);
    }
    return (
      <Modal isOpen={true} onClose={this.props.modals.roomStats.close} size="5xl">
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody p={0}>
            <Card className="game-settings" px={10} py={6} textAlign="center">
              <Heading mb={8}>Room Statistics</Heading>
              <StatGroup py={GROUP_PADDING}>
                <RoomStatistic label="Completion Rate" value={`${completionPercentage}%`}
                               helpText={`${puzzlesCompleted.toLocaleString()} / ${puzzlesPlayed.toLocaleString()}`} />
                <RoomStatistic label="Current Streak" value={currentCompletionStreak.toLocaleString()} helpText="in a row" />
              </StatGroup>
              <StatGroup py={GROUP_PADDING}>
                <RoomStatistic label="Average Solve Time" value={solveTime} helpText={solveTimeHelpText} />
              </StatGroup>
            </Card>
          </ModalBody>
        </ModalContent>
      </Modal>
    );
  }
}
