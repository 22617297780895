export function isElementInViewport(element) {
  let rect = element.getBoundingClientRect();
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
}

export function getTimeDelta(totalElapsedSeconds) {
  let delta = {hours: 0, minutes: 0, seconds: Math.floor(totalElapsedSeconds % 60)};
  let minutes = Math.floor(totalElapsedSeconds / 60);
  if (minutes >= 60) {
    delta.hours = Math.floor(minutes / 60);
    delta.minutes = minutes % 60;
  } else {
    delta.minutes = minutes;
  }
  return delta;
}

export function formatTimeDelta(timeDelta) {
  const { hours, minutes, seconds } = timeDelta;
  let formatted = '';
  if (hours) {
    const pluralizedHours = (hours === 1 ? 'hour' : 'hours');
    formatted += `${hours} ${pluralizedHours}, `;
  }
  const pluralizedMinutes = (minutes === 1 ? 'minute' : 'minutes');
  const pluralizedSeconds = (seconds === 1 ? 'second' : 'seconds');
  formatted += `${minutes} ${pluralizedMinutes} and ${seconds} ${pluralizedSeconds}`;
  return formatted;
}

export function formatTimeDeltaAsClock(timeDelta, includeLeadingZero = true) {
  const { hours, minutes, seconds } = timeDelta;
  let formatted = '';
  if (hours) {
    formatted += `${includeLeadingZero ? hours.toString().padStart(2, '0') : hours}:`;
  }
  formatted += `${includeLeadingZero ? minutes.toString().padStart(2, '0') : minutes}:${seconds.toString().padStart(2, '0')}`;
  return formatted;
}
