import { connect, disconnect, send } from '@giantmachines/redux-websocket';
import { API_BASE, EventTypes, PLAYER_ID_KEY, StatusCodes, WS_BASE } from '../../constants';
import { getISODateString, WebsocketEvent } from '../../commonUtils';

export const ActionTypes = {
  FETCH_ROOM: 'CROSSWORDYE::FETCH_ROOM',
  FETCH_ROOMS: 'CROSSWORDYE::FETCH_ROOMS',
  CREATE_NEW_ROOM: 'CROSSWORDYE::CREATE_NEW_ROOM',
  FETCH_CURRENT_GAME: 'CROSSWORDYE::FETCH_CURRENT_GAME',
  FETCH_GAME: 'CROSSWORDYE::FETCH_GAME',
  FETCH_NEW_GAME: 'CROSSWORDYE::FETCH_NEW_GAME',
  FETCH_CURRENT_PLAYER: 'CROSSWORDYE::FETCH_CURRENT_PLAYER',
  FETCH_PLAYER: 'CROSSWORDYE::FETCH_PLAYER',
  FETCH_PLAYERS: 'CROSSWORDYE::FETCH_PLAYERS',
  CREATE_NEW_PLAYER: 'CROSSWORDYE::CREATE_NEW_PLAYER',
  CHANGE_PLAYER_SETTINGS: 'CROSSWORDYE::CHANGE_PLAYER_SETTINGS',
  FETCH_ROOM_LINK_REQUESTS: 'CROSSWORDYE::FETCH_ROOM_LINK_REQUESTS',
  REQUEST_NEW_ROOM_LINK: 'CROSSWORDYE::REQUEST_NEW_ROOM_LINK',
  RESOLVE_ROOM_LINK_REQUEST: 'CROSSWORDYE::RESOLVE_ROOM_LINK_REQUEST',
  CLEAR_CURRENT_GAME: 'CROSSWORDYE::CLEAR_CURRENT_GAME',
  CLEAR_ERROR: 'CROSSWORDYE::CLEAR_ERROR',
  CLEAR_ROOM_LINK_REQUEST_SUCCEEDED: 'CROSSWORDYE::CLEAR_ROOM_LINK_REQUEST_SUCCEEDED',
  CLEAR_REVEALED_WORD: 'CROSSWORDYE::CLEAR_REVEALED_WORD',
  UPDATE_CELL_CORRECTNESS: 'CROSSWORDYE::UPDATE_CELL_CORRECTNESS',
  UPDATE_ALL_CORRECT_CELLS: 'CROSSWORDYE::UPDATE_ALL_CORRECT_CELLS',
  /* actions provided by the redux-websocket middleware */
  REDUX_WEBSOCKET_OPEN: 'REDUX_WEBSOCKET::OPEN',
  REDUX_WEBSOCKET_CLOSED: 'REDUX_WEBSOCKET::CLOSED',
  REDUX_WEBSOCKET_ERROR: 'REDUX_WEBSOCKET::ERROR',
  REDUX_WEBSOCKET_MESSAGE: 'REDUX_WEBSOCKET::MESSAGE',
};

const GAME_URL = `${API_BASE}/game`;
const PLAYER_URL = `${API_BASE}/player`;
const ROOM_URL = `${API_BASE}/room`;
const ROOM_REQUEST_URL = `${API_BASE}/request`;

function getJSON(response, errorMessage) {
  if (response.ok) {
    return response.json();
  }
  console.log(`${errorMessage}: ${response.status} ${response.statusText}`);
  return {error: errorMessage, status: response.status};
}

function handleError(error, errorMessage) {
  console.log(`${errorMessage}: ${error}`);
  return {error: errorMessage, status: StatusCodes.INTERNAL_SERVER_ERROR};
}

function getRooms(page = 1) {
  const url = new URL(ROOM_URL);
  const params = {};
  if (page > 1) {
    params.page = page;
  }
  url.search = new URLSearchParams(params).toString();
  return fetch(url.toString()).then(response =>
    getJSON(response, `Error occurred while fetching rooms.`)
  ).catch(e =>
    handleError(e, `Unexpected error occurred while fetching rooms.`)
  );
}

function getRoomByID(roomID) {
  return fetch(`${ROOM_URL}/${roomID}`).then(response =>
    getJSON(response, `Error occurred while fetching room ${roomID}.`)
  ).catch(e =>
    handleError(e, `Unexpected error occurred while fetching room ${roomID}.`)
  );
}

function createRoom(playerID, roomCode, password, requestID) {
  const opts = {
    body: JSON.stringify({
      ownerPlayerID: playerID,
      roomCode: roomCode || null,
      password: password || null,
      requestID: requestID || null,
    }),
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST',
  };
  return fetch(ROOM_URL, opts).then(response =>
    getJSON(response, 'Error occurred while creating room.')
  ).catch(e =>
    handleError(e, 'Unexpected error occurred while creating room.')
  );
}

function getGameByID(gameID) {
  return fetch(`${GAME_URL}/${gameID}`).then(response =>
    getJSON(response, `Error occurred while fetching game ${gameID}.`)
  ).catch(e =>
    handleError(e, `Unexpected error occurred while fetching game ${gameID}.`)
  );
}

function createNewGame(gameSettings = null) {
  let body = {roomID: gameSettings.roomID, playerIDs: gameSettings.playerIDs};
  if (gameSettings?.weekday) {
    body.weekday = gameSettings.weekday;
  }
  if (gameSettings?.startDate && gameSettings?.endDate) {
    body.startDate = getISODateString(gameSettings?.startDate);
    body.endDate = getISODateString(gameSettings?.endDate);
  }
  const opts = {
    body: JSON.stringify(body),
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST',
  };
  return fetch(GAME_URL, opts).then(response =>
    getJSON(response, 'Error occurred while creating game.')
  ).catch(e =>
    handleError(e, 'Unexpected error occurred while creating game.')
  );
}

function getPlayers(activeFilter, page = 1) {
  const url = new URL(PLAYER_URL);
  const params = {};
  if (activeFilter !== undefined && activeFilter !== null) {
    params.active = activeFilter;
  }
  if (page > 1) {
    params.page = page;
  }
  url.search = new URLSearchParams(params).toString();
  return fetch(url.toString()).then(response =>
    getJSON(response, `Error occurred while fetching players.`)
  ).catch(e =>
    handleError(e, `Unexpected error occurred while fetching players.`)
  );
}

function getPlayerByID(playerID) {
  return fetch(`${PLAYER_URL}/${playerID}`).then(response =>
    (response.status === StatusCodes.NOT_FOUND) ? null : getJSON(response, `Error occurred while fetching player ${playerID}.`)
  ).catch(e =>
    handleError(e, `Unexpected error occurred while fetching player ${playerID}.`)
  );
}

function createPlayer(name, color) {
  const opts = {
    body: JSON.stringify({
      name: name,
      color: color,
    }),
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST',
  }
  return fetch(PLAYER_URL, opts).then(response =>
    getJSON(response, 'Error occurred while creating player.')
  ).catch(e =>
    handleError(e, 'Unexpected error occurred while creating player.')
  );
}

function updatePlayerSettings(playerID, name, color) {
  return fetch(`${PLAYER_URL}/${playerID}`, {
    body: JSON.stringify({
      name: name,
      color: color,
    }),
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'PATCH',
  }).catch(e => handleError(e, `Unexpected error occurred while updating settings for player ${playerID}.`));
}

function getRoomLinkRequests(resolution, page = 1) {
  const url = new URL(ROOM_REQUEST_URL);
  const params = {};
  if (page > 1) {
    params.page = page;
  }
  if (resolution) {
    params.resolution = resolution;
  }
  url.search = new URLSearchParams(params).toString();
  return fetch(url.toString()).then(response =>
    getJSON(response, 'Error occurred while fetching room link requests.')
  ).catch(e =>
    handleError(e, 'Unexpected error occurred while fetching room link requests.')
  );
}

function submitNewRoomLinkRequest(name, email) {
  const opts = {
    body: JSON.stringify({
      name: name,
      email: email,
    }),
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST',
  }
  return fetch(ROOM_REQUEST_URL, opts).then(response =>
    getJSON(response, 'Error occurred while requesting new room link.')
  ).catch(e =>
    handleError(e, 'Unexpected error occurred while requesting new room link.')
  );
}

function submitRoomLinkRequestResolution(requestID, resolution) {
  const opts = {
    body: JSON.stringify({
      resolution: resolution,
    }),
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'PUT',
  }
  return fetch(`${ROOM_REQUEST_URL}/${requestID}`, opts).then(response =>
    getJSON(response, 'Error occurred while resolving room link request.')
  ).catch(e =>
    handleError(e, 'Unexpected error occurred while resolving room link request.')
  );
}

export function fetchRooms(page = 1) {
  return {
    type: ActionTypes.FETCH_ROOMS,
    payload: getRooms(page),
  };
}

export function fetchRoom(roomID) {
  return {
    type: ActionTypes.FETCH_ROOM,
    payload: getRoomByID(roomID),
  };
}

export function createNewRoom(playerID, roomCode = null, password = null, requestID = null) {
  return {
    type: ActionTypes.CREATE_NEW_ROOM,
    payload: createRoom(playerID, roomCode, password, requestID),
  };
}

export function fetchNewGame(gameSettings) {
  return {
    type: ActionTypes.FETCH_NEW_GAME,
    payload: createNewGame(gameSettings),
  };
}

export function fetchGame(gameID) {
  let promise;
  if (gameID) {
    promise = getGameByID(gameID).then(game => {
      if (game.error) {
        return game;
      }
      if (game.finishedTime !== null) {
        console.log(`Previous game ${gameID} finished. Not reusing game.`);
        return null;
      }
      return game;
    });
  } else {
    promise = null;
  }
  return {
    type: ActionTypes.FETCH_GAME,
    payload: promise,
  };
}

export function fetchPlayers(activeFilter, page = 1) {
  return {
    type: ActionTypes.FETCH_PLAYERS,
    payload: getPlayers(activeFilter, page),
  };
}

export function fetchCurrentPlayer() {
  const playerID = localStorage.getItem(PLAYER_ID_KEY);
  let payload = null;
  if (playerID) {
    payload = getPlayerByID(playerID);
  }
  return {
    type: ActionTypes.FETCH_CURRENT_PLAYER,
    payload: payload,
  };
}

export function fetchPlayer(playerID) {
  return {
    type: ActionTypes.FETCH_PLAYER,
    payload: getPlayerByID(playerID),
  };
}

export function createNewPlayer(name, preferredFontStyle) {
  return {
    type: ActionTypes.CREATE_NEW_PLAYER,
    payload: createPlayer(name, preferredFontStyle),
  };
}

export function changePlayerSettings(playerID, name, color) {
  return {
    type: ActionTypes.CHANGE_PLAYER_SETTINGS,
    payload: updatePlayerSettings(playerID, name, color),
  };
}

export function fetchRoomLinkRequests(resolution, page = 1) {
  return {
    type: ActionTypes.FETCH_ROOM_LINK_REQUESTS,
    payload: getRoomLinkRequests(resolution, page),
  };
}

export function requestNewRoomLink(name, email) {
  return {
    type: ActionTypes.REQUEST_NEW_ROOM_LINK,
    payload: submitNewRoomLinkRequest(name, email),
  };
}

export function resolveRoomLinkRequest(requestID, resolution) {
  return {
    type: ActionTypes.RESOLVE_ROOM_LINK_REQUEST,
    payload: submitRoomLinkRequestResolution(requestID, resolution),
  };
}

export function clearCurrentGame(gameID) {
  return {
    type: ActionTypes.CLEAR_CURRENT_GAME,
    payload: {gameID},
  }
}

export function createNewGameFailed(roomID) {
  return send(new WebsocketEvent(EventTypes.GAME_CREATION_FAILED, {roomID}));
}

export function joinRoom(playerID, roomID) {
  return send(new WebsocketEvent(EventTypes.JOIN_ROOM, {playerID, roomID}));
}

export function joinRoomWithCode(playerID, roomCode, password) {
  let payload = {playerID, roomCode};
  if (password) {
    payload.password = password;
  }
  return send(new WebsocketEvent(EventTypes.JOIN_ROOM_WITH_CODE, payload));
}

export function leaveRoom(playerID, roomID) {
  return send(new WebsocketEvent(EventTypes.LEAVE_ROOM, {playerID, roomID}));
}

export function reassignRoomHost(roomID, newHostPlayerID) {
  return send(new WebsocketEvent(EventTypes.REASSIGN_ROOM_HOST, {roomID, newHostPlayerID}));
}

export function joinGame(context) {
  return send(new WebsocketEvent(EventTypes.JOIN_GAME, {context}));
}

export function abandonGame(context) {
  return send(new WebsocketEvent(EventTypes.ABANDON_GAME, {context}));
}

export function kickPlayer(roomID, playerID, duration) {
  return send(new WebsocketEvent(EventTypes.KICK_PLAYER, {roomID, playerID, duration}));
}

export function moveToCoords(context, coords) {
  return send(new WebsocketEvent(EventTypes.MOVE_TO_COORDS, {context, coords}));
}

export function changeCellContents(context, coords, direction, text) {
  return send(new WebsocketEvent(EventTypes.CHANGE_CELL_CONTENTS, {context, coords, direction, text}));
}

export function revealWord(context, direction, index) {
  return send(new WebsocketEvent(EventTypes.REVEAL_WORD, {context, direction, index}));
}

export function clientConnect(playerID, roomID = null) {
  return send(new WebsocketEvent(EventTypes.CLIENT_CONNECT, {playerID, roomID}));
}

export function updateGameSettings(settings) {
  return send(new WebsocketEvent(EventTypes.GAME_SETTINGS_CHANGED, {roomID: settings.roomID, settings: settings}));
}

export function clearError(error) {
  return {
    type: ActionTypes.CLEAR_ERROR,
    payload: {error},
  };
}

export function clearRoomLinkRequestSucceeded() {
  return {
    type: ActionTypes.CLEAR_ROOM_LINK_REQUEST_SUCCEEDED,
    payload: {},
  };
}

export function clearRevealedWord() {
  return {
    type: ActionTypes.CLEAR_REVEALED_WORD,
    payload: {},
  };
}

export function updateCellCorrectness(coords, isCorrect) {
  return {
    type: ActionTypes.UPDATE_CELL_CORRECTNESS,
    payload: {coords, isCorrect},
  };
}

export function updateAllCorrectCells(correctCells) {
  return {
    type: ActionTypes.UPDATE_ALL_CORRECT_CELLS,
    payload: {correctCells},
  };
}

export function websocketConnect(url = WS_BASE) {
  return connect(url);
}

export function websocketDisconnect() {
  return disconnect();
}
