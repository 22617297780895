import React from 'react';
import moment from 'moment';
import {
  Button,
  Flex,
  Heading,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
} from '@chakra-ui/react';
import { formatTimeDelta, getTimeDelta } from '../../utils';
import Card from '../common/card/Card';
import Timer from './Timer';

export default function GameSummary(props) {
  if (!props.game?.finishedTime) {
    return null;
  }

  const solveTimeInSeconds = Math.floor(moment(props.game.finishedTime).diff(moment(props.game.createdTime)) / 1000);
  const timeDelta = getTimeDelta(solveTimeInSeconds);
  const solveTime = formatTimeDelta(timeDelta);

  return (
    <Modal isOpen={true} closeOnEsc={false} closeOnOverlayClick={false} size="5xl" onClose={() => null}>
      <ModalOverlay />
      <ModalContent>
        <ModalBody p={0}>
          <Card className="game-settings" px={10} py={6} textAlign="center">
            <Heading mb={8}>Puzzle Solved</Heading>
            <Timer seconds={solveTimeInSeconds} pt={3} pb={10} />
            <Heading size="md" pb={3}>Well done! You solved that puzzle in {solveTime}.</Heading>
            <Flex justify="center" mt={12} mb={3} w="100%">
              <Button colorScheme="blue" size="lg" w="25%" onClick={() => props.clearCurrentGame(props.game.gameID)}>
                Return to Lobby
              </Button>
            </Flex>
          </Card>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
