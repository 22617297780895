/*
 NOTE: This file must be manually kept in sync with server/src/models/room.mjs.
 I regret my decisions, and JavaScript is a horrible, horrible language.
 */

import { ROOM_CODE_LENGTH, ROOM_CODE_CHARACTERS } from '../constants';
import { isSuperset } from '../commonUtils';

export function validateRoomCode(roomCode) {
  return (!!roomCode && roomCode?.length === ROOM_CODE_LENGTH && isSuperset(new Set(ROOM_CODE_CHARACTERS), new Set(roomCode)));
}
