import React from 'react';
import { HStack } from '@chakra-ui/react';
import { faPen, faSignOutAlt, faUserTie } from '@fortawesome/free-solid-svg-icons';
import ActionIcon from '../ActionIcon';

function getClaimHostIcon(props) {
  if (!props.isOwner || props.isHost) {
    return null;
  }
  const iconProps = {
    id: 'become-host-icon',
    icon: faUserTie,
    title: 'Become host',
    onClick: props.changeHost,
  };
  return <ActionIcon {...iconProps} />;
}

export default function PlayerListItemIcons(props) {
  if (props.isCurrentPlayer) {
    return (
      <HStack spacing="15px">
        {getClaimHostIcon(props)}
        <ActionIcon id="edit-player" icon={faPen} title="Edit" onClick={props.edit} />
      </HStack>
    );
  }
  if (props.currentPlayerIsHost) {
    return (
      <HStack spacing="15px">
        <ActionIcon id="make-host-icon" icon={faUserTie} title="Make host" onClick={props.changeHost} />
        <ActionIcon id="kick-icon" icon={faSignOutAlt} title="Kick" onClick={props.kickPlayer} />
      </HStack>
    );
  }
  return null;
}
