import React from 'react';
import { Flex, Text, Tooltip } from '@chakra-ui/react';
import {
  faChartLine,
  faCheck,
  faChevronLeft,
  faDoorOpen,
  faEye,
  faInfoCircle,
  faLifeRing,
  faPen,
  faTools,
  faUsers,
} from '@fortawesome/free-solid-svg-icons';
import { EventContext } from '../../../../commonUtils';
import Card from '../../common/card/Card';
import Bold from '../../common/Bold';
import Icon from '../../common/Icon';
import ConfirmLeaveGameDialog from './ConfirmLeaveGameDialog';
import NotesModal from './NotesModal';

const UNICLUE_TOOLTIP_LABEL = 'A "uniclue" puzzle technically only has one list of clues, combining both Across and Down. When two answers share a number, they also share a clue.';

export default function Menu(props) {
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = React.useState(false);
  const [isNotesModalOpen, setIsNotesModalOpen] = React.useState(false);
  const [showLifelines, setShowLifelines] = React.useState(false);

  function closingPopover(action) {
    return function() {
      action();
      props.closePopover();
      setShowLifelines(false);
    }
  }

  let menuItems;
  let onConfirm;
  if (showLifelines) {
    menuItems = [
      {
        icon: faCheck,
        label: 'Check Square',
        onClick: closingPopover(props.checkSelectedSquare),
      },
      {
        icon: faCheck,
        label: 'Check Word',
        onClick: closingPopover(props.checkSelectedWord),
      },
      {
        icon: faCheck,
        label: 'Check Puzzle',
        onClick: closingPopover(props.checkPuzzle),
      },
      {
        icon: faEye,
        label: 'Reveal Square',
        onClick: closingPopover(props.revealSelectedSquare),
      },
      {
        icon: faEye,
        label: 'Reveal Word',
        onClick: closingPopover(props.revealSelectedWord),
      },
    ];
  } else {
    menuItems = [
      {
        icon: faPen,
        label: 'Settings',
        onClick: props.modals.playerEditor.open,
      },
      {
        icon: faUsers,
        label: 'Players',
        onClick: props.modals.playerList.open,
      },
      {
        icon: faLifeRing,
        label: 'Lifelines',
        onClick: () => setShowLifelines(true),
      },
      {
        icon: faChartLine,
        label: 'Statistics',
        onClick: props.modals.roomStats.open,
      },
    ];
    if (props.gameState.playerIsAdmin) {
      menuItems.push({
        icon: faTools,
        label: 'Dashboard',
        onClick: props.modals.adminDashboard.open,
      });
    }
    if (props.gameState.playerIsHost) {
      menuItems.push({
        icon: faDoorOpen,
        label: 'End Game',
        onClick: () => setIsConfirmDialogOpen(true),
      });
      onConfirm = () => props.abandonGame(EventContext.fromProps(props));
    } else {
      menuItems.push({
        icon: faDoorOpen,
        label: 'Leave Game',
        onClick: () => setIsConfirmDialogOpen(true),
      });
      onConfirm = () => props.leaveRoom(props.gameState.playerID, props.gameState.roomID);
    }
  }
  return (
    <Card mb={0} boxShadow="dark-lg" className="popover-card">
      {showLifelines ?
        (
          <Text onClick={() => setShowLifelines(false)} fontSize="xl" align="left" pl={3} className="hover-pointer hover-blue">
            <Icon id="menu-back-icon" icon={faChevronLeft} clickable={true} />
          </Text>
        ) :
        (
          <Flex direction="column" backgroundColor="blue.100" borderBottom="1px solid black" fontSize="lg" p={3}>
            <Text fontSize="2xl"><Bold>Puzzle Info</Bold></Text>
            <Text><Bold>Author:</Bold> {props.puzzle.author}</Text>
            <Text><Bold>Editor:</Bold> {props.puzzle.editor}</Text>
            <Text><Bold>Day of Week:</Bold> {props.puzzle.weekday}</Text>
            <Text><Bold>Size:</Bold> {props.puzzle.size.rows} x {props.puzzle.size.columns}</Text>
            {props.puzzle.hasOwnProperty('uniclue') && (
              <Text>
                <Bold>Uniclue:</Bold> Yes
                <Tooltip p={2} label={UNICLUE_TOOLTIP_LABEL}>
                  <Text as="span" pl={2}>
                    <Icon id="uniclue-info-icon" icon={faInfoCircle} clickable={true} />
                  </Text>
                </Tooltip>
              </Text>
            )}
            {props.puzzle.hasOwnProperty('notes') && (
              <Text className="hover-pointer hover-blue" onClick={() => setIsNotesModalOpen(true)}>
                <Bold>Notes</Bold>
              </Text>
            )}
          </Flex>
        )
      }
      <ul className="list-group">
        {menuItems.map(item => {
          const key = item.label.toLowerCase();
          let classes = 'list-group-item';
          if (item.disabled) {
            classes += ' menu-item-disabled';
          } else {
            classes += ' hover-pointer list-group-menu-item';
          }
          return (
            <li key={key} className={classes} onClick={item.onClick} title={item.title}>
              <Flex align="center" pr={6} userSelect="none">
                <Icon id={`${key}-menu-item-icon`} icon={item.icon} clickable={!item.disabled} />
                <Text ml={3}>{item.label}</Text>
              </Flex>
            </li>
          );
        })}
      </ul>
      <ConfirmLeaveGameDialog abandon={props.gameState?.playerIsHost} isOpen={isConfirmDialogOpen} gameID={props.gameState?.gameID}
                              onClose={() => setIsConfirmDialogOpen(false)} onConfirm={onConfirm} />
      <NotesModal isOpen={isNotesModalOpen} onClose={() => setIsNotesModalOpen(false)} notes={props.puzzle.notes} />
    </Card>
  );
}
