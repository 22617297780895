import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Box, Button, Checkbox, Flex, Heading, Progress, Select, Stack, Text } from '@chakra-ui/react';
import { getISODateString, isValidPuzzleDate } from '../../../../commonUtils';
import { Weekdays } from '../../../../constants';
import { GameSettings as Settings } from '../../../../models/game';
import { getPlayerName } from '../../../reducers/game_reducer';
import Card from '../../common/card/Card';
import GameSetting from './GameSetting';

const DATE_FORMAT = 'M/d/yyyy';

const MIN_DATE = new Date(1942, 1, 15);
const TODAY = new Date();

export default class GameSettings extends React.Component {
  constructor(props) {
    super(props);
    const settings = props.room?.settings || Settings.default(props.roomID);
    this.state = {
      endDate: (settings.endDate ? new Date(settings.endDate) : TODAY),
      endDateRef: React.createRef(),
      startDate: (settings.startDate ? new Date(settings.startDate) : TODAY),
      startDateRef: React.createRef(),
      dateRangeEnabled: (!!settings.startDate && !!settings.endDate),
      showEndDatePicker: false,
      showStartDatePicker: false,
      weekday: settings.weekday,
    };
    this.createNewGame = this.createNewGame.bind(this);
    this.onEndDateChanged = this.onEndDateChanged.bind(this);
    this.onStartDateChanged = this.onStartDateChanged.bind(this);
    this.onDateRangeCheckboxChanged = this.onDateRangeCheckboxChanged.bind(this);
    this.onWeekdayChanged = this.onWeekdayChanged.bind(this);
    this.toggleStartDatePicker = this.toggleStartDatePicker.bind(this);
    this.toggleEndDatePicker = this.toggleEndDatePicker.bind(this);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.gameSettings !== this.props.gameSettings) {
      let newState = {weekday: this.props.gameSettings.weekday};
      if (this.props.gameSettings.startDate && this.props.gameSettings.endDate) {
        newState.startDate = new Date(this.props.gameSettings.startDate);
        newState.endDate = new Date(this.props.gameSettings.endDate);
      }
      this.setState(newState);
    }

    if (prevProps.gameStarting && !this.props.gameStarting && !this.props.game &&
      this.props.playerID === this.props.room?.hostPlayerID) {
      this.props.createNewGameFailed(this.props.roomID);
    }
  }

  onEndDateChanged(date) {
    this.setState({endDate: date});
    this.updateGameSettings(this.state.dateRangeEnabled, this.state.weekday, this.state.startDate, date);
  }

  onStartDateChanged(date) {
    this.setState({endDate: date, startDate: date});
    this.updateGameSettings(this.state.dateRangeEnabled, this.state.weekday, date, date);
  }

  onDateRangeCheckboxChanged() {
    const enabled = !this.state.dateRangeEnabled;
    console.log(`setting dateRangeEnabled to ${enabled}`);
    this.setState({dateRangeEnabled: enabled});
    this.updateGameSettings(enabled, this.state.weekday, this.state.startDate, this.state.endDate);
  }

  onWeekdayChanged(event) {
    const weekday = event.target.value;
    this.setState({weekday: weekday});
    this.updateGameSettings(this.state.dateRangeEnabled, weekday, this.state.startDate, this.state.endDate);
  }

  toggleEndDatePicker() {
    this.setState({showEndDatePicker: !this.state.showEndDatePicker});
  }

  toggleStartDatePicker() {
    this.setState({showStartDatePicker: !this.state.showStartDatePicker});
  }

  updateGameSettings(dateRangeEnabled, weekday, startDate, endDate) {
    if (!dateRangeEnabled) {
      startDate = null;
      endDate = null;
    }
    this.props.updateGameSettings(new Settings(this.props.roomID, weekday, startDate, endDate));
  }

  createNewGame() {
    const playerIDs = Object.keys(this.props.players);
    const startDate = (this.state.dateRangeEnabled ? this.state.startDate : null);
    const endDate = (this.state.dateRangeEnabled ? this.state.endDate : null);
    const gameSettings = new Settings(this.props.roomID, this.state.weekday, startDate, endDate, playerIDs);
    this.props.fetchNewGame(gameSettings);
  }

  render() {
    if (this.props.gameStarting) {
      return (
        <Card className="game-settings">
          <Box className="game-starting" textAlign="center">
            <Heading>A new game is starting, please wait...</Heading>
            <Progress hasStripe isAnimated borderRadius="md" colorScheme="blue" size="lg" mx={8} mt={10} value={100} />
          </Box>
        </Card>
      );
    }
    const disabled = (this.props.playerID !== this.props.room?.hostPlayerID);
    const startGameDisabled = (disabled || Object.keys(this.props.players).length === 0);
    const hostName = (this.props.room ? getPlayerName(this.props.room.hostPlayerID) : 'host');
    const DatePickerInput = React.forwardRef((props, ref) => (
      <Button ref={ref} colorScheme="white" textColor="black" border="1px solid black" onClick={props.onClick}
              isDisabled={disabled || !props.enabled}>
        {props.value}
      </Button>
    ));
    const selectClasses = (disabled ? '' : 'hover-pointer');
    return (
      <Card className="game-settings" px={8} py={6}>
        <Heading mb={5} textAlign="center">Game Settings</Heading>
        <GameSetting label="Puzzle Selection">
          <Stack spacing={5} pt={0}>
            <Flex direction="row" pt={0}>
              <Text fontSize="lg" align="center" lineHeight="100%" pr={5} my="auto">
                Day of Week
              </Text>
              <Select focusBorderColor="blue.400" minW="100px" w="auto" value={this.state.weekday} onChange={this.onWeekdayChanged}
                      isDisabled={disabled || (this.state.dateRangeEnabled && getISODateString(this.state.startDate) === getISODateString(this.state.endDate))}
                      className={selectClasses}>
                {Object.entries(Weekdays).map(([key, value]) => <option key={key} value={key}>{value}</option>)}
              </Select>
            </Flex>
            <Flex direction="row">
              <Checkbox colorScheme="blue" size="lg" isDisabled={disabled} value={this.state.dateRangeEnabled}
                        onChange={this.onDateRangeCheckboxChanged} defaultIsChecked={this.state.dateRangeEnabled} pr={5}>
                Date Range
              </Checkbox>
              <Flex direction="row" pt={0} fontSize="lg">
                <DatePicker dateFormat={DATE_FORMAT} minDate={MIN_DATE} maxDate={TODAY} filterDate={isValidPuzzleDate} selected={this.state.startDate}
                            onChange={this.onStartDateChanged} showMonthDropdown={true} showYearDropdown={true} todayButton="Today"
                            customInput={<DatePickerInput ref={this.state.startDateRef} value={this.state.startDate} enabled={this.state.dateRangeEnabled} />}
                            selectsStart startDate={this.state.startDate} endDate={this.state.endDate} />
                <Text align="left" px={3} py={1}>to</Text>
                <DatePicker dateFormat={DATE_FORMAT} minDate={this.state.startDate} maxDate={TODAY} filterDate={isValidPuzzleDate} selected={this.state.endDate}
                            onChange={this.onEndDateChanged} showMonthDropdown={true} showYearDropdown={true} todayButton="Today"
                            customInput={<DatePickerInput ref={this.state.endDateRef} value={this.state.endDate} enabled={this.state.dateRangeEnabled} />}
                            selectsEnd startDate={this.state.startDate} endDate={this.state.endDate} />
              </Flex>
            </Flex>
          </Stack>
        </GameSetting>
        <Flex justify="center" mt={5} mb={3}>
          {disabled ?
            <Heading size="lg">Waiting for {hostName} to start a new game...</Heading> :
            <Button colorScheme="blue" size="lg" disabled={startGameDisabled} onClick={this.createNewGame}>Start New Game</Button>
          }
        </Flex>
      </Card>
    );
  }
}
